import React from 'react'
import clsx from 'clsx'
import Block from '../../components/Block/Block'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { embedDefaultConfig } from './embedMeta'
import { EmbedBlock } from '@sceneio/schemas/lib/types'

function Embed({
  id,
  components: { block },
  editor,
}: EmbedBlock = embedDefaultConfig) {
  const { className, embedHtml = '', ...restBlockProps } = block || {}

  return (
    <Block
      editor={editor}
      className={clsx('sc-embed', className)}
      id={id}
      {...restBlockProps}
    >
      <div
        className="sc-embed-container"
        dangerouslySetInnerHTML={{ __html: embedHtml }}
      />
    </Block>
  )
}

export const embedPlugins: PluginsType = {
  js: [],
  css: [],
}
export default Embed
