import { createContext, useContext } from 'react'
import { search } from 'jmespath'
import { flatten, unflatten } from 'flat'
import { UseFormReturnType } from './useForm'
import { normalizeJMESPath } from '@sceneio/tools'
import type { JSX } from 'react'

export type FormContextType = UseFormReturnType & {
  portalContainer?: HTMLElement
  error?: {
    name: string
    message: string
  }
}

export const FormContext = createContext<FormContextType>({} as any)

const getDataSubsetByName = ({
  name,
  data = {},
}: {
  name?: string
  data?: Record<string, any>
}): Record<string, any> => {
  if (!name) {
    return data
  }

  return flatten({
    [name]: search(unflatten(data), normalizeJMESPath(name)) ?? undefined,
  })
}

export const FormProvider = ({
  children,
  ...rest
}: FormContextType & {
  children: JSX.Element | JSX.Element[]
}) => (
  <FormContext.Provider value={rest as FormContextType}>
    {children}
  </FormContext.Provider>
)

export const useFormContext = (name?: string) => {
  const formContext = useContext(FormContext)
  return {
    ...formContext,
    portalContainer: formContext.portalContainer || document.body,
    values: getDataSubsetByName({ name, data: formContext.values }),
    placeholders: getDataSubsetByName({ name, data: formContext.placeholders }),
    registerValues: getDataSubsetByName({
      name,
      data: formContext.registerValues,
    }),
    error: formContext.errors?.find((error) => error.name === name),
  }
}
