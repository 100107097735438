import React from 'react'
import TestimonialCarousel1 from './TestimonialCarousel1'
import { testimonialCarousel1DefaultConfig } from './testimonialCarousel1Meta'

export function TestimonialCarousel1Preview(config: any) {
  const defaultData =
    Object.keys(config).length !== 0
      ? config
      : testimonialCarousel1DefaultConfig

  return <TestimonialCarousel1 {...defaultData} />
}
