import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import Block from '../../../components/Block/Block'
import clsx from 'clsx'
import Media from '../../../components/Media/Media'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import Image from '../../../components/Image/Image'
import Text from '../../../components/Text/Text'
import Form from '../../../components/Form/Form'
import Countdown from '../../../components/Countdown/Countdown'
import { HeroCountdownBlock } from '@sceneio/schemas/lib/types'
import { heroCountdownDefaultConfig } from './heroCountdownMeta'

function HeroCountdown({
  id,
  components: {
    block,
    logo,
    tagline,
    heading,
    text,
    background,
    countdown,
    form,
  },
  editor,
  preview,
}: HeroCountdownBlock = heroCountdownDefaultConfig) {
  const { className, ...restBlockProps } = block || {}

  const heroCountdownClass = clsx(
    `sc-hero-countdown sc-banner sc-banner--default`,
    className,
  )
  return (
    <Block
      className={heroCountdownClass}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <Media
        {...background}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'background',
        }}
        className={clsx('sc-banner__background', background.className)}
        cid={resolveComponentId(id, 'background')}
        preview={preview}
      />
      <Image
        {...logo}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'logo',
        }}
      />
      <Text
        {...tagline}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'tagline',
        }}
        cid={resolveComponentId(id, 'tagline')}
      />
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
          wysiwyg: {
            type: 'HEADING',
          },
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <Countdown
        {...countdown}
        editor={{ ...editor?.component, blockId: id, id: 'countdown' }}
        cid={resolveComponentId(id, 'countdown')}
        preview={preview}
      />
      <Form
        {...form}
        editor={{ ...editor?.component, blockId: id, id: 'form' }}
        preview={preview}
        cid={resolveComponentId(id, 'form')}
      />
    </Block>
  )
}

export default HeroCountdown

export const heroCountdownPlugins: PluginsType = {
  js: ['video', 'animation', 'countdown', 'form'],
  css: [],
}
