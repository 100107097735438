import React from 'react'
import clsx from 'clsx'
import type { HeroWithForm3Block } from '@sceneio/schemas/lib/types'
import Block from '../../../components/Block/Block'
import Media from '../../../components/Media/Media'
import Text from '../../../components/Text/Text'
import Image from '../../../components/Image/Image'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { heroWithForm3DefaultConfig } from './heroWithForm3Meta'
import Form from '../../../components/Form/Form'

function HeroWithForm3({
  id,
  components: {
    block,
    logo,
    subheading,
    text,
    heading,
    background,
    author,
    form,
  },
  editor,
  preview,
}: HeroWithForm3Block = heroWithForm3DefaultConfig) {
  const { className, ...restBlockProps } = block || {}

  const heroWithForm3Class = clsx(
    `sc-hero-with-form-3 sc-banner sc-banner--default`,
    className,
  )

  return (
    <Block
      className={heroWithForm3Class}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      {background && (
        <Media
          {...background}
          editor={{
            ...editor?.component,
            blockId: id,
            id: 'background',
          }}
          components={{
            ...background.components,
            caption: {
              show: false,
            },
            video: {
              ...('video' in background.components
                ? {
                    isBackgroundVideo: true,
                    ...background.components.video,
                  }
                : undefined),
            },
          }}
          cover
          className={clsx('sc-banner__background', background.className)}
          cid={resolveComponentId(id, 'background')}
          preview={preview}
        />
      )}
      <Image
        {...logo}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'logo',
        }}
      />
      <Text
        {...subheading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'subheading',
          wysiwyg: {
            type: 'HEADING',
          },
        }}
        cid={resolveComponentId(id, 'subheading')}
      />
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
          wysiwyg: {
            type: 'HEADING',
          },
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <Text
        {...author}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'author',
        }}
        cid={resolveComponentId(id, 'author')}
      />
      <Form
        {...form}
        editor={{ ...editor?.component, blockId: id, id: 'form' }}
        preview={preview}
        cid={resolveComponentId(id, 'form')}
      />
    </Block>
  )
}

export const heroWithForm3Plugins: PluginsType = {
  js: ['video', 'animation', 'social', 'form'],
  css: [],
}
export default HeroWithForm3
