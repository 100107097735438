import {
  Combobox,
  ComboboxGroup,
  ComboboxGroupLabel,
  ComboboxList,
  ComboboxProvider,
  Select,
  SelectPopover,
  SelectProvider,
} from '@ariakit/react'
import * as Form from '@radix-ui/react-form'
import { useOnClickOutside } from '@sceneio/hooks/lib/useOnClickOutside'
import { Icon } from '@sceneio/ui-icons'
import clsx from 'clsx'
import { CSSProperties, ReactNode, startTransition, useState } from 'react'
import { useFormContext } from '../../../form/context/FormContext'
import { SelectOption } from './SelectOption'
import type { JSX } from 'react'

export type SearchableSelectPropType = {
  label?: ReactNode | string
  name: string
  onChange?: (arg0: string) => void
  className?: string
  required?: boolean
  style?: CSSProperties
  isDisabled?: boolean
  hidden?: boolean
  fonts: {
    value: string
    label: string | JSX.Element
    title: string
    uploaded?: boolean
    disabled?: boolean
  }[]
  usedFonts?: SearchableSelectPropType['fonts']
  placeholder?: string
  onUploadFontClick?: ({
    onFontCreateCallback,
  }: {
    onFontCreateCallback: (fontFamily: string) => void
  }) => void
  onFontSelect?: (fontFamily: string) => void
}

export const SearchableFontSelect = ({
  name,
  hidden,
  style,
  className,
  label,
  placeholder,
  isDisabled,
  fonts = [],
  usedFonts = [],
  onChange,
  onUploadFontClick,
  onFontSelect,
}: SearchableSelectPropType) => {
  const { placeholders, values, setValue, forceBreakpoint } = useFormContext({
    name,
  })
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)

  const containerClasses = clsx(
    'sc-searchable-font-select tw-container tw-text-xs',
    {
      'tw-hidden': hidden,
    },
    className,
  )

  const filterUsedFontsFromAllFonts = () => {
    const filtered = fonts.filter(
      (o) => !usedFonts.find(({ value }) => value === o.value),
    )
    return filtered
  }

  const filterFontsBySearchQuery = () => {
    return query === ''
      ? filterUsedFontsFromAllFonts()
      : fonts.filter(
          (item) =>
            item.title
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')) ||
            item.value === values[name],
        )
  }

  const ref = useOnClickOutside(
    () => {
      if (open) {
        setOpen(false)
      }
    },
    {
      ignoreClass: [
        'radix-modal__overlay',
        'radix-modal',
        'select-trigger',
        'select-popover',
      ],
    },
  )

  let selectPlaceholder = placeholders[name] || placeholder || 'Select'
  let value = values[name] ? values[name].toString() : undefined

  const availableFontsFamilies = fonts.map(({ value }) => value)
  // check if font exists
  if (
    (value && !availableFontsFamilies.includes(value)) ||
    (!value &&
      placeholders[name] &&
      !availableFontsFamilies.includes(placeholders[name]))
  ) {
    value = undefined
    selectPlaceholder = 'Non existing font'
  }

  return (
    <Form.Field className={containerClasses} style={style} name={name}>
      <Form.Label
        className={clsx(
          'tw-bg-form-field-bg tw-rounded tw-flex tw-flex-wrap tw-items-stretch tw-justify-between tw-relative hover:tw-outline hover:tw-outline-1 hover:tw-outline-offset-[-1px] hover:tw-outline-border-color focus-within:tw-outline-border-color focus-within:tw-outline-1 focus-within:tw-outline-offset-[-1px] focus-within:tw-outline',
          { 'tw-bg-white': forceBreakpoint && !name.includes(forceBreakpoint) },
        )}
      >
        {label && (
          <div className={clsx('tw-py-2 tw-pl-2 tw-text-label-color')}>
            {label}
          </div>
        )}
        <div className="tw-relative tw-flex-1" ref={ref}>
          <ComboboxProvider
            open={open}
            setOpen={setOpen}
            resetValueOnHide
            setValue={(value) => {
              startTransition(() => {
                setQuery(value)
              })
            }}
          >
            <SelectProvider
              open={open}
              value={value || selectPlaceholder}
              setValue={(value) => {
                onFontSelect?.(value)
                if (onChange) {
                  onChange(value)
                } else {
                  setValue(name, value)
                }
              }}
            >
              <Select
                className={clsx(
                  'select-trigger tw-inline-flex tw-items-center tw-py-2 tw-px-2 tw-justify-between tw-w-full focus-visible:tw-outline-none data-[placeholder=true]:tw-text-label-color',
                  { 'tw-opacity-50 tw-cursor-not-allowed': isDisabled },
                  className,
                )}
                data-placeholder={!value}
              >
                {fonts.find((option) => option.value === values[name])?.label ||
                  fonts.find((option) => option.value === selectPlaceholder)
                    ?.label}
                <Icon
                  provider="phosphor"
                  icon="CaretDown"
                  className="tw-mr-1 tw-ml-auto"
                />
              </Select>
              <SelectPopover
                sameWidth
                className="select-popover tw-overflow-hidden tw-bg-white tw-text-xs tw-rounded tw-border tw-border-border-color tw-z-[100] tw-my-px"
                onBlurCapture={(event) => {
                  // prevent the blur event from propagating window and messing up with useWindowFocus hook
                  event.preventDefault()
                  event.stopPropagation()
                  event.nativeEvent.preventDefault()
                  event.nativeEvent.stopPropagation()
                }}
              >
                {open && (
                  <>
                    <div className="combobox-wrapper tw-flex tw-items-center tw-gap-1 tw-px-3 tw-border-b tw-border-border-color">
                      <div className="combobox-icon">
                        <Icon provider="phosphor" icon="MagnifyingGlass" />
                      </div>
                      <Combobox
                        autoSelect
                        placeholder="Search"
                        className="combobox focus-visible:tw-outline-none tw-w-full tw-py-3 tw-appearance-none"
                        onBlurCapture={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                        }}
                        setValueOnChange
                        onKeyDownCapture={(e) => {
                          if (
                            e.key === 'ArrowUp' ||
                            e.key === 'ArrowDown' ||
                            e.key === 'Enter'
                          ) {
                            return
                          }
                          e.stopPropagation()
                        }}
                      />
                    </div>
                    <ComboboxList className="tw-overflow-y-scroll tw-max-h-[300px]">
                      {usedFonts.length > 0 && !query && (
                        <ComboboxGroup className="tw-border-b tw-pt-[2px] tw-pb-1">
                          <ComboboxGroupLabel className="tw-py-2 tw-px-3 tw-text-label-color">
                            Used in the website
                          </ComboboxGroupLabel>
                          {usedFonts.map((font) => (
                            <SelectOption {...font} key={font.value} />
                          ))}
                        </ComboboxGroup>
                      )}
                      <ComboboxGroup className="tw-pt-[2px] tw-pb-1">
                        <ComboboxGroupLabel className="tw-py-2 tw-px-3 tw-text-label-color">
                          All fonts
                        </ComboboxGroupLabel>
                        {filterFontsBySearchQuery().map((font) => (
                          <SelectOption {...font} key={font.value} />
                        ))}
                      </ComboboxGroup>
                    </ComboboxList>
                    <div className="tw-border-t tw-border-border-color tw-text-center">
                      <button
                        type="button"
                        className="tw-text-primary tw-font-semibold tw-w-full tw-p-3 hover:tw-bg-primary-8"
                        onClick={() => {
                          if (onUploadFontClick) {
                            onUploadFontClick({
                              onFontCreateCallback: (fontFamily: string) => {
                                if (fontFamily) {
                                  setValue(name, fontFamily)
                                }
                              },
                            })
                          }
                        }}
                      >
                        Upload fonts
                      </button>
                    </div>
                  </>
                )}
              </SelectPopover>
            </SelectProvider>
          </ComboboxProvider>
        </div>
      </Form.Label>
    </Form.Field>
  )
}
