import clsx from 'clsx'
import React from 'react'
import Threads from '@sceneio/ui-icons/lib/providers/scene/icons/Threads'
import X from '@sceneio/ui-icons/lib/providers/scene/icons/X'
import type { SCENE_ICONS } from '@sceneio/ui-icons/lib/providers/scene/SceneIcon'
import type { PHOSPHOR_ICON } from '@sceneio/ui-icons/lib/providers/phosphor/PhosphorIcon'

const AVAILABLE_SCENE_ICONS: Partial<typeof SCENE_ICONS> = {
  threads: Threads,
  x: X,
}
type CommonIconProps = {
  className?: string
  style?: React.CSSProperties
  weight?: 'thin' | 'light' | 'regular' | 'bold' | 'fill' | 'duotone'
  alt?: string
  color?: string
  size?: string | number
  mirrored?: boolean
}

export type PhosphorIconProps = CommonIconProps & {
  provider: 'phosphor'
  icon: PHOSPHOR_ICON
}

export type SceneIconProps = CommonIconProps & {
  provider: 'scene'
  icon: keyof typeof SCENE_ICONS
}

export function WebIcon({
  icon,
  provider,
  className,
  style,
  weight,
  color,
  size,
  mirrored,
}: PhosphorIconProps | SceneIconProps) {
  const dashedCaseIcon = icon.replace(
    /[A-Z]/g,
    (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase(),
  )

  const webIconStyles = { ...style }
  if (size) {
    webIconStyles.fontSize = size
  }
  if (color) {
    webIconStyles.color = color
  }
  if (mirrored) {
    webIconStyles.transform = 'scale(-1, 1)'
  }

  if (provider === 'scene') {
    const IconComponent = AVAILABLE_SCENE_ICONS[icon]
    if (!IconComponent) return null

    return (
      <IconComponent
        className={clsx('svg-inline--sc', className)}
        style={style}
      />
    )
  }

  return (
    <i
      style={webIconStyles}
      className={clsx(className, 'ph', {
        [`ph-${dashedCaseIcon}`]: dashedCaseIcon,
        [`ph-${weight}`]: weight,
      })}
    />
  )
}
