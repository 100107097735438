import {
  BlockComponent,
  TestimonialCarousel1Block,
  TestimonialVariant1Card,
} from '@sceneio/schemas/lib/types'
import { randomString } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { sliderVariant2Defaults } from '../../../components/Slider/SliderVariant2/sliderVariant2Meta'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../../Buttons/form/additionalFormFieldRenderers'

const SLIDER_DEFAULTS = sliderVariant2Defaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

export const testimonialCarousel1ItemSampleFactory =
  (): TestimonialVariant1Card => ({
    id: randomString(),
    meta: {
      dataType: 'container-component',
    },
    horizontalAlignment: {
      data: {
        '--horizontal-alignment-default': 'flex-start',
      },
    },
    border: {
      meta: {
        dataType: 'border-style',
      },
      data: {
        default: {
          '--border-default-radius-top-left-default': '16px',
          '--border-default-radius-top-right-default': '16px',
          '--border-default-radius-bottom-left-default': '16px',
          '--border-default-radius-bottom-right-default': '16px',
        },
      },
    },
    background: {
      responsiveConfig: {
        default: {
          color: '#F4F4F4',
        },
      },
    },
    padding: {
      data: {
        '--padding-default-top': '1.5rem',
        '--padding-default-bottom': '1.5rem',
        '--padding-default-left': '1.5rem',
        '--padding-default-right': '1.5rem',
      },
    },
    components: {
      logo: {
        meta: {
          dataType: 'image-component',
        },
        show: true,
        responsiveConfig: {
          default: {
            src: 'https://assets.scene.io/global/placeholders/scene-logo-placeholder.svg',
            alt: 'Logo',
            width: 150,
          },
        },
        padding: {
          data: {
            '--padding-default-bottom': '1.5rem',
          },
        },
      },
      quote: {
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare.',
        ),
        as: 'p',
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-weight-default': 'bold',
            },
          },
        },
        padding: {
          data: {
            '--padding-default-bottom': '1.5rem',
          },
        },
      },
      authorContainer: {
        meta: {
          dataType: 'container-component',
        },
        flow: {
          data: {
            '--flow-default': 'row',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-start',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        gap: {
          data: {
            '--column-gap-default': '1rem',
          },
        },
        components: {
          avatar: {
            show: true,
            meta: {
              dataType: 'image-component',
            },
            responsiveConfig: {
              default: {
                src: 'https://assets.scene.io/global/placeholders/avatar-placeholder.png',
                alt: 'Avatar',
                width: 56,
              },
            },
            border: {
              meta: {
                dataType: 'border-style',
              },
              data: {
                default: {
                  '--border-default-radius-bottom-left-default': '50px',
                  '--border-default-radius-bottom-right-default': '50px',
                  '--border-default-radius-top-left-default': '50px',
                  '--border-default-radius-top-right-default': '50px',
                },
              },
            },
          },
          name: {
            children: textToLexical('Name Surname'),
            as: 'p',
            meta: {
              dataType: 'text-component',
            },
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  '--font-default-weight-default': 'bold',
                  '--font-default-line-height-default': '24px',
                },
              },
            },
          },
          company: {
            children: textToLexical('Position, Company name'),
            as: 'p',
            meta: {
              dataType: 'text-component',
            },
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  '--font-default-line-height-default': '24px',
                },
              },
            },
          },
        },
      },
    },
  })

export const testimonialCarousel1ComponentMeta: BlockComponentMeta<TestimonialCarousel1Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        meta: {
          dataType: 'block-component',
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
      } as BlockComponent,
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
            rowGap: '1.5rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: BLOCK_CONTAINER_DEFAULTS.padding,
      },
      type: 'blockContainer',
    },
    tagline: {
      path: 'components.tagline',
      name: 'Tagline',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.tagline',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        children: textToLexical('Tagline'),
        as: 'p',
        show: false,
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: {
          data: {
            '--padding-xs-left': '2rem',
            '--padding-xs-right': '2rem',
          },
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-weight-default': 'bold',
            },
          },
        },
      },
      type: 'text',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
        children: textToLexical('Customer testimonials', 'h2'),
        as: 'h2',
        padding: {
          data: {
            '--padding-default-left': '20%',
            '--padding-default-right': '20%',
            '--padding-xs-left': '0%',
            '--padding-xs-right': '0%',
            '--padding-md-left': '10%',
            '--padding-md-right': '10%',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        show: true,
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        ),
        as: 'p',
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: {
          data: {
            '--padding-default-left': '20%',
            '--padding-default-right': '20%',
            '--padding-xs-left': '0%',
            '--padding-xs-right': '0%',
            '--padding-md-left': '10%',
            '--padding-md-right': '10%',
          },
        },
        show: true,
      },
      type: 'text',
    },
    slider: {
      path: 'components.slider',
      name: 'Slider',
      type: 'sliderVariant2',
      components: {
        slides: {
          type: 'container',
          name: 'Slides',
          path: 'components.slides',
          configEditor: {
            disable: true,
          },
          disableToggleShow: true,
          persistentSelectionId: 'slider',
          components: {
            items: {
              isArrayComponent: true,
              sampleFactory: testimonialCarousel1ItemSampleFactory,
              path: 'items',
              name: 'Testimonial',
              type: 'container',
              disableToggleShow: true,
              configEditor: {
                formProps: {
                  name: 'group',
                  registerValues:
                    containerComponentFormRegisterValuesFactory('group'),
                },
              },
              components: {
                logo: {
                  type: 'image',
                  name: 'Logo',
                  path: 'components.logo',
                },
                quote: {
                  type: 'text',
                  name: 'Quote',
                  path: 'components.quote',
                  inlineEditors: [
                    {
                      editorType: 'inlineText',
                      editorPath: 'components.quote',
                      editorOptions: {
                        isNestedArrayItem: true,
                      },
                    },
                  ],
                },
                authorContainer: {
                  type: 'container',
                  name: 'Author Container',
                  path: 'components.authorContainer',
                  components: {
                    avatar: {
                      type: 'image',
                      name: 'Avatar',
                      path: 'components.avatar',
                    },
                    name: {
                      type: 'text',
                      name: 'Name',
                      path: 'components.name',
                      inlineEditors: [
                        {
                          editorType: 'inlineText',
                          editorPath: 'components.name',
                          editorOptions: {
                            isNestedArrayItem: true,
                          },
                        },
                      ],
                    },
                    company: {
                      type: 'text',
                      name: 'Company',
                      path: 'components.company',
                      inlineEditors: [
                        {
                          editorType: 'inlineText',
                          editorPath: 'components.company',
                          editorOptions: {
                            isNestedArrayItem: true,
                          },
                        },
                      ],
                    },
                  },
                  configEditor: {
                    additionalFormFields: {
                      layout: {
                        formFieldRenderer:
                          buttonContainerLayoutAdditionalFormFieldRenderer,
                      },
                    },
                    formProps: {
                      name: 'component',
                      registerValues:
                        containerComponentFormRegisterValuesFactory(
                          'component',
                        ),
                    },
                  },
                },
              },
            },
          },
        },
        controlsContainer: {
          type: 'container',
          name: 'Controls container',
          path: 'components.controlsContainer',
          components: {
            navigation: {
              type: 'container',
              name: 'Navigation',
              path: 'components.navigation',
              components: {
                buttonPrev: {
                  path: 'components.buttonPrev',
                  name: 'Button prev',
                  type: 'button',
                },
                buttonNext: {
                  path: 'components.buttonNext',
                  name: 'Button next',
                  type: 'button',
                },
              },
              configEditor: {
                formProps: {
                  registerValues:
                    containerComponentFormRegisterValuesFactory('group'),
                },
              },
            },
            pagination: {
              type: 'sliderPagination',
              name: 'Pagination',
              path: 'components.pagination',
            },
          },
          configEditor: {
            formProps: {
              registerValues:
                containerComponentFormRegisterValuesFactory('component'),
            },
          },
        },
      },
      defaultConfig: {
        ...SLIDER_DEFAULTS,
        itemsPerView: {
          responsiveConfig: {
            default: 3,
            md: 2,
            xs: 1,
          },
        },
        gap: {
          data: {
            '--column-gap-default': '40px',
          },
        },
        margin: {
          data: {
            '--margin-default-top': '2.5rem',
          },
        },
        effect: 'slide',
        components: {
          ...SLIDER_DEFAULTS.components,
          slides: {
            ...SLIDER_DEFAULTS.components.slides,
            items: [
              testimonialCarousel1ItemSampleFactory(),
              testimonialCarousel1ItemSampleFactory(),
              testimonialCarousel1ItemSampleFactory(),
              testimonialCarousel1ItemSampleFactory(),
              testimonialCarousel1ItemSampleFactory(),
              testimonialCarousel1ItemSampleFactory(),
            ],
          },
        },
      },
      configEditor: {
        formProps: {
          excludedSettings: ['effect'],
        },
      },
    },
  }

export const testimonialCarousel1BlockMeta: BlockMeta<TestimonialCarousel1Block> =
  {}

export const testimonialCarousel1DefaultConfig =
  blockDefaultConfigFactory<TestimonialCarousel1Block>({
    componentMeta: testimonialCarousel1ComponentMeta,
    blockMeta: testimonialCarousel1BlockMeta,
    version: 1,
  })
