import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from './../../store'
import { resolveZoomBoundaries } from './helpers/resolveZoomBoundaries'
import {
  SelectedModules,
  TriggerScrollIntoView,
  WhiteboardContentEntitiesDeleteQueryVarsType,
  WhiteboardContentEntitiesMetaUpdateQueryVarsType,
  WhiteboardModule,
  WhiteboardSliceType,
} from './whiteboardSliceTypes'
import { createGraphqlAsyncThunkByDocument } from '../../helpers/createGraphqlAsyncThunk'
import { WhiteboardContentEntitiesDeleteCommand } from '../../localHistoryCommands/WhiteboardContentEntitiesDeleteCommand'
import { asyncThunkFetcher } from '../../helpers/asyncThunkFetcher'
import { setBlockPicker } from '../editor/editorSlice'
import { requestContentBlocksByAiV2 } from '../content/contentSlice'

export * from './whiteboardSliceSelectors'
export * from './whiteboardSliceMemoizedSelectorHooks'

// ---------------
// Initial State
// ---------------
export const initialState: WhiteboardSliceType = {
  mode: 'SELECT',
  modeData: {},
  prevMode: 'SELECT',
  zoom: 75,
  internalZoom: 75,
  showBackToContent: false,
  selectedModules: [],
  triggerExternalAdd: [],
  triggerZoomToFit: null,
  triggerScrollIntoView: null,
  hiddenModules: [],
} as WhiteboardSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Thunks
// ---------------

export const updateWhiteboardContentEntitiesMeta =
  createGraphqlAsyncThunkByDocument<
    'WhiteboardContentEntitiesMetaUpdateMutationDocument',
    'updateWhiteboardContentEntitiesMeta',
    WhiteboardContentEntitiesMetaUpdateQueryVarsType
  >()(
    'whiteboardContentEntities/updateMeta',
    async ({ queryVariables }, thunkAPI) => {
      const content = thunkAPI.getState().content.entity
      const contentId = content?.id

      return await asyncThunkFetcher({
        query: 'WhiteboardContentEntitiesMetaUpdateMutationDocument',
        selector: 'updateWhiteboardContentEntitiesMeta',
        variables: { ...queryVariables, contentId: contentId! },
        thunkAPI,
        rejectQueries: ['CONTENT', 'CONTENT_MODULES'],
      })
    },
  )

export const deleteWhiteboardContentEntities =
  createGraphqlAsyncThunkByDocument<
    'WhiteboardContentEntitiesDeleteMutationDocument',
    'deleteWhiteboardContentEntities',
    WhiteboardContentEntitiesDeleteQueryVarsType
  >()(
    'whiteboardContentEntities/delete',
    async ({ queryVariables }, thunkAPI) => {
      const content = thunkAPI.getState().content.entity
      const contentId = content?.id

      return await asyncThunkFetcher({
        query: 'WhiteboardContentEntitiesDeleteMutationDocument',
        selector: 'deleteWhiteboardContentEntities',
        variables: { ...queryVariables, contentId: contentId! },
        thunkAPI,
        rejectQueries: ['CONTENT', 'CONTENT_MODULES'],
      })
    },
  )

export const setModeOnce =
  (mode: WhiteboardSliceType['mode']) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().editor.mode !== mode) {
      dispatch(setMode(mode))
    }
  }

export const deleteSelectedWhiteboardEntities =
  (customSelectedWhiteboardModules?: SelectedModules) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const selectedWhiteboardModules = getState().whiteboard.selectedModules

    const resolvedSelectedWhiteboardModules =
      customSelectedWhiteboardModules || selectedWhiteboardModules

    const selectedWhiteboardEntitiesWithoutComments =
      resolvedSelectedWhiteboardModules
        .filter(({ type }) => !['COMMENT', 'PAGE'].includes(type))
        .map(({ id, entity }) => ({ id, entity }))

    dispatch(
      new WhiteboardContentEntitiesDeleteCommand({
        queryVariables: {
          deleteInput: selectedWhiteboardEntitiesWithoutComments,
        },
      }),
    )

    dispatch(setSelectedModules([]))

    return
  }

// ---------------
// Reducer
// ---------------

export const whiteboardSlice = createSlice({
  name: 'whiteboard',
  initialState,
  reducers: {
    resetWhiteboardState: () => initialState,
    setMode: (state, action: PayloadAction<WhiteboardSliceType['mode']>) => {
      state.mode = action.payload
      state.modeData = {}
    },
    setPrevMode: (state, action: PayloadAction<{ shouldAlign?: true }>) => {
      const newMode = state.prevMode
      state.prevMode = action?.payload?.shouldAlign ? newMode : state.mode

      state.mode = newMode
    },
    triggerModeWithData: (
      state,
      action: PayloadAction<{
        mode: WhiteboardSliceType['mode']
        data: Record<string, any>
        shouldMerge?: boolean
      }>,
    ) => {
      const shouldMerge = action.payload.shouldMerge || false
      state.mode = action.payload.mode

      if (shouldMerge) {
        state.modeData = {
          ...(state.modeData || {}),
          ...action.payload.data,
        }
      } else {
        state.modeData = action.payload.data
      }
    },
    triggerExternalAdd: (
      state,
      action: PayloadAction<WhiteboardSliceType['triggerExternalAdd']>,
    ) => {
      state.triggerExternalAdd = action.payload
    },
    setSelectedModules: (
      state,
      action: PayloadAction<WhiteboardSliceType['selectedModules']>,
    ) => {
      state.selectedModules = action.payload
    },
    hideModules: (
      state,
      action: PayloadAction<WhiteboardSliceType['hiddenModules']>,
    ) => {
      state.hiddenModules = [...state.hiddenModules, ...action.payload]
    },
    showModule: (state, action: PayloadAction<WhiteboardModule>) => {
      state.hiddenModules = state.hiddenModules.filter(
        (module) => module !== action.payload,
      )
    },
    toggleShowModule: (state, action: PayloadAction<WhiteboardModule>) => {
      const module = action.payload
      const moduleIsHidden = state.hiddenModules.includes(module)

      if (moduleIsHidden) {
        state.hiddenModules = state.hiddenModules.filter(
          (module) => module !== action.payload,
        )
      } else {
        state.hiddenModules = [...state.hiddenModules, module]
      }
    },
    showAllModules: (state) => {
      state.hiddenModules = []
    },
    setInternalZoomTo: (
      state,
      action: PayloadAction<WhiteboardSliceType['internalZoom']>,
    ) => {
      state.internalZoom = action.payload
    },
    setZoomTo: (state, action: PayloadAction<WhiteboardSliceType['zoom']>) => {
      const newZoom = resolveZoomBoundaries(action.payload)
      state.zoom = newZoom
      state.internalZoom = newZoom
    },
    setZoomBy: (state, action: PayloadAction<number>) => {
      const by = action.payload
      const newZoom = resolveZoomBoundaries(state.zoom + by)
      state.zoom = newZoom
      state.internalZoom = newZoom
    },
    setTriggerZoomToFit: (state) => {
      state.triggerZoomToFit = Date.now()
    },
    setTriggerScrollIntoView: (
      state,
      action: PayloadAction<TriggerScrollIntoView>,
    ) => {
      state.triggerScrollIntoView = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase('USER:LOGOUT', () => {
        return initialState
      })
      .addCase(setBlockPicker, (state, action) => {
        const { show } = action.payload
        if (!show) {
          state.mode = 'SELECT'
          state.modeData = {}
        }
      })
      .addCase(requestContentBlocksByAiV2.pending, (state, action) => {
        state.triggerZoomToFit = Date.now()
      })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useWhiteboardDispatch: () => AppDispatch = useDispatch
export const useWhiteboardSelector: TypedUseSelectorHook<RootState> =
  useSelector

export const {
  setMode,
  triggerExternalAdd,
  setPrevMode,
  setInternalZoomTo,
  setZoomTo,
  setZoomBy,
  setSelectedModules,
  resetWhiteboardState,
  setTriggerZoomToFit,
  setTriggerScrollIntoView,
  showAllModules,
  showModule,
  hideModules,
  toggleShowModule,
  triggerModeWithData,
} = whiteboardSlice.actions

export default whiteboardSlice.reducer
