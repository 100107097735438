import { createContext, createRef, ReactNode, RefObject } from 'react'

export type OnSubmitCallbackArgs = {
  name?: string
  data?: string | Record<string, any>[]
  isItemArray?: boolean
  operation?: 'ADD' | 'DELETE' | 'UPDATE'
  additionalItemsToAdd?: Array<{
    path: string
    config?: Object
    isArrayPath?: boolean
    cloneExistingItem?: boolean
    position?: number
  }>
  additionalItemsToDelete?: Array<{
    path: string
    isArrayPath: boolean
    position?: number
  }>
}

type CallbackType = (data: OnSubmitCallbackArgs) => void

export type WysiwygContextType = {
  onSubmit: CallbackType
  resolvePathFromId?: (id: string) => string
  anchorRef: RefObject<HTMLElement>
  disabled?: boolean
  config?: {
    enableAI?: boolean
  }
  onFontSelect?: (fontFamily: string) => void
}

type WysiwygProviderType = WysiwygContextType & {
  children: ReactNode
}

export const WysiwygContext = createContext<WysiwygContextType>({
  resolvePathFromId: () => '',
  onSubmit: () => {},
  anchorRef: createRef<HTMLElement>(),
  onFontSelect: () => {},
})

export function WysiwygContextProvider({
  children,
  onSubmit,
  anchorRef,
  resolvePathFromId,
  disabled,
  config,
  onFontSelect,
}: WysiwygProviderType) {
  return (
    <WysiwygContext.Provider
      value={{
        onSubmit,
        anchorRef,
        resolvePathFromId,
        disabled,
        config,
        onFontSelect,
      }}
    >
      {children}
    </WysiwygContext.Provider>
  )
}
