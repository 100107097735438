import type { JSX } from 'react'
export const InheritedSettingsWrapper = ({
  children,
  onEditClick,
}: {
  children: JSX.Element
  onEditClick: () => void
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-bg-form-field-bg tw-p-2 tw-rounded">
      <span className="tw-flex tw-items-center tw-text-xs tw-text-label-color">
        Inherited from desktop:
        <button
          type="button"
          className="tw-ml-auto tw-text-black"
          onClick={onEditClick}
        >
          Edit desktop
        </button>
      </span>
      <div className="tw-pointer-events-none">{children}</div>
    </div>
  )
}
