import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { errorNotification } from '@sceneio/cms-notifications'
import { getComponentConfigPath } from '@sceneio/content-shared-helpers'
import {
  appendExternalMediaResourceReference,
  appendInternalLinkResourceReference,
  appendMediaResourceReference,
  deleteReference,
  getReferencePath,
  referencesCleanup,
} from '@sceneio/referencing-tools'
import { ReferencesType } from '@sceneio/referencing-tools/lib/referencesTypes'
import {
  validateContent,
  validateContentBlock,
  validateContentBlocks,
} from '@sceneio/schemas'
import {
  assocJMESPath,
  interleave,
  mergeDeepRight,
  removeEmptyValues,
} from '@sceneio/tools'
import blocksFactory from '@sceneio/ui-core/src/blocks'
import { search } from 'jmespath'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { asyncThunkFetcher } from '../../helpers/asyncThunkFetcher'
import { createAppendCidThunk } from '../../helpers/createAppendCidThunk'
import {
  createGraphqlAsyncThunk,
  createGraphqlAsyncThunkByDocument,
} from '../../helpers/createGraphqlAsyncThunk'
import { flattenUpdate } from '../../helpers/flattenUpdate'
import { DeleteBlockCommand } from '../../localHistoryCommands/DeleteBlockCommand'
import { UpdateBlockCommand } from '../../localHistoryCommands/UpdateBlockCommand'
import { UpdateReusableBlockCommand } from '../../localHistoryCommands/UpdateReusableBlockCommand'
import { GraphqlThunkData } from '../../types'
import {
  initialState as editorSliceInitialState,
  fetchReusableBlocks,
  setCanvasState,
  setSelectedEntity,
} from '../editor/editorSlice'
import type { AppDispatch, RootState } from './../../store'
import {
  AddContentBlocksQueryVarsType,
  AssignReusableContentBlockQueryVarsType,
  BlockType,
  ContentBlockMetaType,
  ContentDataType,
  ContentSliceType,
  ContentType,
  DetachReusableContentBlockQueryVarsType,
  ReferencesToProcess,
  RegenerateBlocksQueryVarsType,
  RequestBlocksByAiv2QueryVarsType,
  ReusableContentBlockOutput,
  SelectContentBlockQueryVarsType,
  UpdateContentBlockOrderQueryVarsType,
  UpdateContentBlockQueryVarsType,
  UpdateContentBlocksQueryVarsType,
  UpdateContentDataQueryVarsType,
  UpdateContentQueryVarsType,
  UpdateReusableContentBlockQueryVarsType,
  UpdateReusableContentBlocksQueryVarsType,
} from './contentSliceTypes'
import {
  deleteWhiteboardContentEntities,
  updateWhiteboardContentEntitiesMeta,
} from '../whiteboard/whiteboardSlice'
import { thunkReject } from '../../helpers/thunkReject'
import { WhiteboardContentEntitiesMetaUpdateResType } from '../whiteboard/whiteboardSliceTypes'
import { appendDocumentResourceReference } from '@sceneio/referencing-tools/lib/appendReference'
import { materializeContentBlock } from './helpers'
import { materializeSnippets } from '@sceneio/snippets-tools'
import {
  ContentBlockUpdateDataInput,
  ReusableContentBlockUpdateDataInput,
} from '@sceneio/graphql-queries/dist/generated/graphqlTypes'

export * from './contentSliceSelectors'
export * from './contentSliceMemoizedSelectorHooks'

const blocksMap = blocksFactory()

const SKIP_CUSTOM_BLOCKS_UPDATE = ['dynamicMasonryGrid']

export const APPEND_REFERENCE_METHOD_BY_TYPE_MAP = {
  media: appendMediaResourceReference,
  'external-media': appendExternalMediaResourceReference,
  'internal-link': appendInternalLinkResourceReference,
  'internal-file': appendDocumentResourceReference,
} as const

// ---------------
// Initial State
// ---------------
export const initialState: ContentSliceType = {
  entity: null,
  status: 'idle',
  error: null,
  requests: {
    updateBlockRequestId: '',
    updateContentDataRequestId: '',
    updateContentRequestId: '',
    updateBlocksRequestId: '',
    updateReusableBlocksRequestId: '',
  },
} as ContentSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Thunks
// ---------------

export const fetchContent = createGraphqlAsyncThunkByDocument<
  'ContentDataQueryDocument',
  'content'
>()('content/fetchData', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'ContentDataQueryDocument',
    selector: 'content',
    variables: queryVariables,
    thunkAPI,
    validateDataCallback: (data) => {
      if (!data) {
        return { isValid: false, error: 'Content Data not provided' }
      }

      return { isValid: true }
    },
  })
})

export const updateContent = createGraphqlAsyncThunkByDocument<
  'UpdateContentDataDocument',
  'updateContent',
  UpdateContentQueryVarsType
>()('content/updateContent', async ({ queryVariables }, thunkAPI) => {
  const snippets = thunkAPI.getState().snippets.entities
  const content = thunkAPI.getState().content.entity
  const currentContentData = content?.data

  const { data, references } = queryVariables

  // content's data is optional during update, we need to used current content's data if not provided
  const contentData = data || currentContentData

  // safety check if provided references are materialized correctly
  const materializedContent = materializeSnippets({
    data: {
      data: contentData,
      references,
    },
    snippets,
  })

  const { isValid, error } = validateContent({
    data: materializedContent.data,
  })

  if (!isValid) {
    errorNotification({
      content: 'Invalid content data',
      log: {
        message: '[updateContent]: Content data validation failed',
        data: {
          dataToValidate: materializedContent.data,
          validationError: error,
          thunkQueryVariables: queryVariables,
        },
      },
    })

    return thunkReject({
      code: 'invalid_data',
      message: 'Invalid content data',
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  }

  const updatedReferences = referencesCleanup({
    references: queryVariables.references,
    data: { data: contentData },
  })

  return await asyncThunkFetcher({
    query: 'UpdateContentDataDocument',
    selector: 'updateContent',
    variables: {
      ...queryVariables,
      data: contentData,
      references: updatedReferences,
    },
    thunkAPI,
    rejectQueries: ['CONTENT'],
  })
})

export const updateContentData = createGraphqlAsyncThunkByDocument<
  'UpdateContentDataDocument',
  'updateContent',
  UpdateContentDataQueryVarsType
>()(
  'content/updateContentData',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const {
      configPath,
      configValue,
      referencesToProcess = [],
      referencesToReplace,
      preferences,
    } = queryVariables
    const content = thunkAPI.getState().content.entity
    const contentId = content?.id
    const references = content?.references as ReferencesType
    const layoutConfig = content?.data || {}

    let layoutData = layoutConfig

    if (configPath !== undefined && configValue !== undefined) {
      if (thunkOptions?.shouldReplaceValue) {
        layoutData = assocJMESPath(
          configPath,
          configValue,
          layoutConfig,
        ) as ContentDataType
      } else {
        layoutData = mergeDeepRight({ config: configValue }, layoutConfig)
      }
    }

    const { isValid, error } = validateContent({
      data: layoutData,
    })

    if (!isValid) {
      errorNotification({
        content: 'Invalid content data',
        log: {
          message: '[updateContent]: Content data validation failed',
          data: {
            dataToValidate: layoutData,
            validationError: error,
            thunkQueryVariables: queryVariables,
          },
        },
      })

      return thunkReject({
        code: 'invalid_data',
        message: 'Invalid content data',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    // process references
    let updatedReferences = references
    referencesToProcess.forEach(({ type, operation, data }) => {
      const referencePath = getReferencePath({
        configPath: data.path!,
        data: { data: layoutData },
      })

      if (operation === 'add') {
        const appendReferencesMethod =
          APPEND_REFERENCE_METHOD_BY_TYPE_MAP[
            type as keyof typeof APPEND_REFERENCE_METHOD_BY_TYPE_MAP
          ]

        if (appendReferencesMethod) {
          updatedReferences = appendReferencesMethod({
            references,
            data: {
              id: data.id!,
              path: referencePath,
            },
          })
        }
      }
    })

    // final references cleanup
    updatedReferences = referencesCleanup({
      references: updatedReferences,
      data: { data: layoutData },
    })

    return await asyncThunkFetcher({
      query: 'UpdateContentDataDocument',
      selector: 'updateContent',
      variables: {
        id: contentId,
        data: layoutData,
        preferences: { ...content?.preferences, ...preferences },
        references:
          (referencesToReplace ? referencesToReplace : updatedReferences) || [],
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  },
)

export const detachReusableContentBlock = createGraphqlAsyncThunk<
  ContentType | null,
  DetachReusableContentBlockQueryVarsType
>(
  'content/detachReusableContentBlock',
  async ({ queryVariables }, thunkAPI) => {
    const { blockId } = queryVariables
    const reusableContentBlock = thunkAPI
      .getState()
      .content.entity?.contentBlocks.find(
        (block) => block.id === blockId && block.isReusable,
      )

    const reusableContentBlockConfig =
      reusableContentBlock?.reusableContentBlockDraft?.config ||
      reusableContentBlock?.config

    if (reusableContentBlock) {
      await thunkAPI.dispatch(
        new DeleteBlockCommand({ queryVariables: { id: blockId } }),
      )

      return thunkAPI.dispatch(
        addBlockWithCid({
          queryVariables: {
            contentPropertyName: reusableContentBlock.contentPropertyName,
            config: reusableContentBlockConfig,
            position: reusableContentBlock.order,
            type: reusableContentBlock.type,
            isRenderable: true,
            customType: reusableContentBlock.customType,
            references: [],
          },
        }),
      )
    }

    return Promise.resolve(null)
  },
)

export const setContentPreferencesUserHasInteracted = createGraphqlAsyncThunk<
  ContentType | null,
  undefined
>('content/setContentPreferencesUserHasInteracted', async (_, thunkAPI) => {
  const content = thunkAPI.getState().content.entity
  const contentBlocks = thunkAPI.getState().content.entity?.contentBlocks
  const contentId = content?.id
  const contentReferences = content?.references

  const currentUserHasInteracted = content?.preferences?.userHasInteracted
  const isEmptyContent = contentBlocks?.length === 0

  if (currentUserHasInteracted && !isEmptyContent) {
    return null
  }

  return await asyncThunkFetcher({
    query: 'UpdateContentDataDocument',
    selector: 'updateContent',
    variables: {
      id: contentId,
      preferences: {
        ...content?.preferences,
        userHasInteracted: isEmptyContent ? false : true,
      },
      references: contentReferences,
    },
    rejectQueries: ['CONTENT'],
    thunkAPI,
  })
})

export const updateContentPath = createGraphqlAsyncThunkByDocument<
  'PathUpdateMutationDocument',
  'updatePath'
>()('content/updatePath', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'PathUpdateMutationDocument',
    selector: 'updatePath',
    variables: queryVariables,
    rejectQueries: ['CONTENT'],
    thunkAPI,
  })
})

export const addBlock = createGraphqlAsyncThunkByDocument<
  'ContentBlockAddMutationDocument',
  'addContentBlock',
  AddContentBlockQueryVarsType
>()('content/block/add', async ({ queryVariables, thunkOptions }, thunkAPI) => {
  const contentId = thunkAPI.getState().content.entity?.id

  return await asyncThunkFetcher({
    query: 'ContentBlockAddMutationDocument',
    selector: 'addContentBlock',
    variables: { ...queryVariables, contentId },
    thunkAPI,
    rejectQueries: ['CONTENT'],
  }).then((res) => {
    if (thunkOptions?.onSuccess) {
      thunkOptions.onSuccess()
    }

    thunkAPI.dispatch(setContentPreferencesUserHasInteracted())

    return res
  })
})

export const requestContentBlocksByAiV2 = createGraphqlAsyncThunkByDocument<
  'RequestContentBlocksByAiv2Document',
  'requestContentBlocksByAIv2',
  RequestBlocksByAiv2QueryVarsType
>()(
  'content/blocks/requestByAIV2',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const state = thunkAPI.getState()
    const contentId = state.content.entity?.id
    const aiChatContext = state.editor.aiChatContext

    return await asyncThunkFetcher({
      query: 'RequestContentBlocksByAiv2Document',
      selector: 'requestContentBlocksByAIv2',
      variables: {
        ...queryVariables,
        contentId,
        inputData: queryVariables.inputData || aiChatContext || {},
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess()
      }
      return res
    })
  },
)

export const addBlocks = createGraphqlAsyncThunkByDocument<
  'ContentBlocksAddMutationDocument',
  'addContentBlocks',
  AddContentBlocksQueryVarsType
>()(
  'content/blocks/add',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const contentId = thunkAPI.getState().content.entity?.id
    return await asyncThunkFetcher({
      query: 'ContentBlocksAddMutationDocument',
      selector: 'addContentBlocks',
      variables: { ...queryVariables, contentId },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess()
      }
      return res
    })
  },
)

export const regenerateBlocks = createGraphqlAsyncThunk<
  void,
  RegenerateBlocksQueryVarsType
>('content/blocks/regenerate', async ({ queryVariables }, thunkAPI) => {
  const allRenderableContentBlocksIds =
    thunkAPI
      .getState()
      .content.entity?.contentBlocks.filter(({ isRenderable }) => isRenderable)
      .map(({ id }) => id) || []

  thunkAPI.dispatch(setCanvasState('REGENERATING'))

  if (allRenderableContentBlocksIds.length === 0) {
    thunkAPI
      .dispatch(addBlocks({ queryVariables: { ...queryVariables } }))
      .then(() => {
        thunkAPI.dispatch(setCanvasState(null))
      })
  } else {
    thunkAPI
      .dispatch(
        deleteBlocks({
          queryVariables: {
            ids: allRenderableContentBlocksIds,
          },
        }),
      )
      .then(() => {
        thunkAPI
          .dispatch(addBlocks({ queryVariables: { ...queryVariables } }))
          .then(() => {
            thunkAPI.dispatch(setCanvasState(null))
          })
      })
  }
})

export const duplicateContentBlock = createGraphqlAsyncThunkByDocument<
  'ContentBlockDuplicateMutationDocument',
  'duplicateContentBlock'
>()('content/block/duplicate', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'ContentBlockDuplicateMutationDocument',
    selector: 'duplicateContentBlock',
    variables: queryVariables,
    thunkAPI,
    rejectQueries: ['CONTENT'],
  })
})

export const assignReusableContentBlock = createGraphqlAsyncThunkByDocument<
  'AssignReusableContentBlockDocument',
  'assignReusableContentBlock',
  AssignReusableContentBlockQueryVarsType
>()(
  'content/reusableContentBlock/assign',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const contentId = thunkAPI.getState().content.entity?.id
    return await asyncThunkFetcher({
      query: 'AssignReusableContentBlockDocument',
      selector: 'assignReusableContentBlock',
      variables: { ...queryVariables, contentId },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess()
      }
      return res
    })
  },
)

export const addBlockWithCid = createAppendCidThunk<typeof addBlock>(addBlock)
export const duplicateBlockWithCid = createAppendCidThunk<
  typeof duplicateContentBlock
>(duplicateContentBlock)
export const assignReusableContentBlockWithCid = createAppendCidThunk<
  typeof assignReusableContentBlock
>(assignReusableContentBlock)

export const deleteBlock = createGraphqlAsyncThunkByDocument<
  'ContentBlockDeleteMutationDocument',
  'deleteContentBlock'
>()('content/block/delete', async ({ queryVariables }, thunkAPI) => {
  thunkAPI.dispatch(setSelectedEntity(editorSliceInitialState.selectedEntity))

  let id = queryVariables?.id

  return await asyncThunkFetcher({
    query: 'ContentBlockDeleteMutationDocument',
    selector: 'deleteContentBlock',
    variables: { id },
    thunkAPI,
    rejectQueries: ['CONTENT'],
  }).then((res) => {
    thunkAPI.dispatch(setContentPreferencesUserHasInteracted())
    return res
  })
})

export const deleteBlocks = createGraphqlAsyncThunkByDocument<
  'ContentBlocksDeleteMutationDocument',
  'deleteContentBlocks'
>()('content/blocks/delete', async ({ queryVariables }, thunkAPI) => {
  let ids = queryVariables?.ids
  return await asyncThunkFetcher({
    query: 'ContentBlocksDeleteMutationDocument',
    selector: 'deleteContentBlocks',
    variables: { ids },
    thunkAPI,
    rejectQueries: ['CONTENT'],
  }).then((res) => {
    thunkAPI.dispatch(setContentPreferencesUserHasInteracted())
    return res
  })
})

export const mergeReusableContentBlockDraft = createGraphqlAsyncThunkByDocument<
  'MergeReusableContentBlockDraftDocument',
  'mergeReusableContentBlockDraft'
>()(
  'content/reusableContentBlock/mergeDraft',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const contentId = thunkAPI.getState().content.entity?.id
    return await asyncThunkFetcher({
      query: 'MergeReusableContentBlockDraftDocument',
      selector: 'mergeReusableContentBlockDraft',
      variables: {
        ...queryVariables,
        contentId,
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess()
      }

      return res
    })
  },
)

// delete parent reusable content block which is not assigned to any content
export const deleteReusableContentBlock = createGraphqlAsyncThunkByDocument<
  'DeleteReusableContentBlockDocument',
  'deleteReusableContentBlock'
>()(
  'content/reusableContentBlock/delete',
  async ({ queryVariables }, thunkAPI) => {
    return await asyncThunkFetcher({
      query: 'DeleteReusableContentBlockDocument',
      selector: 'deleteReusableContentBlock',
      variables: queryVariables,
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  },
)

export const deleteReusableContentBlockDraft =
  createGraphqlAsyncThunkByDocument<
    'DeleteReusableContentBlockDocument',
    'deleteReusableContentBlock'
  >()(
    'content/reusableContentBlock/deleteDraft',
    async ({ queryVariables }, thunkAPI) => {
      const contentId = thunkAPI.getState().content.entity?.id
      return await asyncThunkFetcher({
        query: 'DeleteReusableContentBlockDocument',
        selector: 'deleteReusableContentBlock',
        variables: {
          ...queryVariables,
          contentId,
        },
        rejectQueries: ['CONTENT'],
        thunkAPI,
      })
    },
  )

export const makeContentBlockReusable = createGraphqlAsyncThunkByDocument<
  'SetAsReusableContentBlockDocument',
  'setAsReusableContentBlock'
>()(
  'content/block/makeContentBlockReusable',
  async ({ queryVariables }, thunkAPI) => {
    const result = await asyncThunkFetcher({
      query: 'SetAsReusableContentBlockDocument',
      selector: 'setAsReusableContentBlock',
      variables: queryVariables,
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })

    thunkAPI.dispatch(fetchReusableBlocks({ queryVariables: {} }))

    return result
  },
)

export type SetContentBlocksAsRenderableThunkType = GraphqlThunkData<{
  setRenderableInput: {
    id: string
    position: number
    meta?: ContentBlockMetaType
  }[]
}>

export const setContentBlocksAsRenderable = createGraphqlAsyncThunkByDocument<
  'SetAsRenderableContentBlocksMutationDocument',
  'setContentBlocksAsRenderable'
>()(
  'content/blocks/setContentBlocksAsRenderable',
  async ({ queryVariables }, thunkAPI) => {
    return await asyncThunkFetcher({
      query: 'SetAsRenderableContentBlocksMutationDocument',
      selector: 'setContentBlocksAsRenderable',
      variables: queryVariables,
      thunkAPI,
    })
  },
)

export type SetContentBlocksAsNonRenderableThunkType = GraphqlThunkData<{
  setNonRenderableInput: {
    id: string
    meta: ContentBlockMetaType
  }[]
}>

export const setContentBlocksAsNonRenderable =
  createGraphqlAsyncThunkByDocument<
    'SetAsNonRenderableContentBlocksMutationDocument',
    'setContentBlocksAsNonRenderable'
  >()(
    'content/blocks/setContentBlocksAsNonRenderable',
    async ({ queryVariables }, thunkAPI) => {
      return await asyncThunkFetcher({
        query: 'SetAsNonRenderableContentBlocksMutationDocument',
        selector: 'setContentBlocksAsNonRenderable',
        variables: queryVariables,
        rejectQueries: ['CONTENT'],
        thunkAPI,
      })
    },
  )

export const updateReusableContentBlock = createGraphqlAsyncThunkByDocument<
  'ReusableContentBlocksUpdateMutationDocument',
  'updateReusableContentBlocks',
  UpdateReusableContentBlockQueryVarsType
>()(
  'content/reusableContentBlock/update',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const {
      blockId,
      name,
      configPath,
      configValue,
      customConfigValue,
      referencesToProcess = [],
      referencesToReplace,
    } = queryVariables

    const contentId = thunkAPI.getState().content.entity?.id
    let block = thunkAPI
      .getState()
      .content.entity?.contentBlocks.find((block) => block.id === blockId)

    if (!block) {
      return thunkReject({
        code: 'data_not_found',
        message: 'Block not found',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    if (block.isReusable) {
      block = block?.reusableContentBlockDraft || block
    }

    // TODO temp disallow update on dynamic masonry grid, in the future we want to refactor block meta to disallow updates on editor level
    if (
      block.customType &&
      SKIP_CUSTOM_BLOCKS_UPDATE.includes(block.customType)
    ) {
      return thunkReject({
        code: 'block_skip',
        message: 'Block Update Skipped',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    let blockConfig = block.config || {}
    if (configPath !== undefined && configValue !== undefined) {
      if (thunkOptions?.shouldReplaceValue) {
        blockConfig = assocJMESPath(configPath, configValue, block.config)
      } else {
        blockConfig = flattenUpdate(block.config, configValue, configPath)
      }

      if (thunkOptions?.shouldRemoveEmptyValues) {
        blockConfig = assocJMESPath(
          configPath,
          removeEmptyValues(search(blockConfig, configPath)),
          blockConfig,
        )
      }
    }

    const blockCustomConfig = customConfigValue || block.customConfig || {}

    const dataToValidate = {
      ...block,
      config: blockConfig,
    }
    const { isValid, error } = validateContentBlock(dataToValidate)

    if (!isValid) {
      errorNotification({
        content: 'Invalid block data',
        log: {
          message: `[updateReusableContentBlock]:  "${block.type}" block data validation failed`,
          data: {
            dataToValidate: dataToValidate,
            validationError: error,
            thunkQueryVariables: queryVariables,
          },
        },
      })

      return thunkReject({
        code: 'invalid_data',
        message: 'Invalid block data',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    const { references: blockReferences } = block

    // process references
    let updatedReferences = blockReferences
    referencesToProcess.forEach(({ type, operation, data }) => {
      const referencePath = getReferencePath({
        configPath: data.path!,
        data: { config: blockConfig },
      })

      if (operation === 'delete') {
        updatedReferences = deleteReference({
          references: updatedReferences,
          path: referencePath,
        })
      }

      if (operation === 'add') {
        const appendReferencesMethod =
          APPEND_REFERENCE_METHOD_BY_TYPE_MAP[
            type as keyof typeof APPEND_REFERENCE_METHOD_BY_TYPE_MAP
          ]

        if (appendReferencesMethod) {
          updatedReferences = appendReferencesMethod({
            blockReferences,
            data: {
              id: data.id!,
              path: referencePath,
            },
          })
        }
      }
    })

    // final references cleanup
    updatedReferences = referencesCleanup({
      references: updatedReferences,
      data: { config: blockConfig },
    })

    return await asyncThunkFetcher({
      query: 'ReusableContentBlocksUpdateMutationDocument',
      selector: 'updateReusableContentBlocks',
      variables: {
        updateInput: [
          {
            id: block.id,
            name,
            contentId,
            config: blockConfig,
            customConfig: blockCustomConfig,
            references:
              (referencesToReplace ? referencesToReplace : updatedReferences) ||
              [],
          },
        ],
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  },
)

export const updateContentBlocks = createGraphqlAsyncThunkByDocument<
  'ContentBlocksUpdateMutationDocument',
  'updateContentBlocks',
  UpdateContentBlocksQueryVarsType
>()('content/blocks/update', async ({ queryVariables }, thunkAPI) => {
  const { updateInput } = queryVariables
  const snippets = thunkAPI.getState().snippets.entities
  const currentContentBlocks = thunkAPI.getState().content.entity?.contentBlocks

  // contentBlock's config is optional during update, we need to used current contentBlock's config if not provided
  const contentBlocks: ContentBlockUpdateDataInput[] = updateInput.map(
    (contentBlock) => {
      const { config: currentContentBlockConfig } =
        currentContentBlocks?.find(({ id }) => id === contentBlock.id) || {}

      return {
        ...contentBlock,
        config: contentBlock.config || currentContentBlockConfig || {},
      }
    },
  )

  // safety check if provided references are materialized correctly
  const materializedContentBlocks = contentBlocks.map((contentBlock) =>
    materializeContentBlock({
      block: contentBlock,
      snippets,
    }),
  )

  const { isValid, error } = validateContentBlocks({
    contentBlocks: materializedContentBlocks,
  })

  if (!isValid) {
    errorNotification({
      content: 'Invalid content blocks',
      log: {
        message: '[updateContentBlocks]: Content blocks data validation failed',
        data: {
          dataToValidate: materializedContentBlocks,
          validationError: error,
          thunkQueryVariables: queryVariables,
        },
      },
    })

    return thunkReject({
      code: 'invalid_data',
      message: 'Invalid content data',
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  }

  const contentBlocksWithCleanedReferences = contentBlocks.map((block) => ({
    ...block,
    references: referencesCleanup({
      references: block?.references,
      data: { config: block?.config },
    }),
  }))

  return await asyncThunkFetcher({
    query: 'ContentBlocksUpdateMutationDocument',
    selector: 'updateContentBlocks',
    variables: {
      updateInput: contentBlocksWithCleanedReferences,
    },
    thunkAPI,
    rejectQueries: ['CONTENT'],
  }).then((res) => {
    thunkAPI.dispatch(setContentPreferencesUserHasInteracted())
    return res
  })
})

export const updateReusableContentBlocks = createGraphqlAsyncThunkByDocument<
  'ReusableContentBlocksUpdateMutationDocument',
  'updateReusableContentBlocks',
  UpdateReusableContentBlocksQueryVarsType
>()(
  'content/reusableContentBlocks/update',
  async ({ queryVariables }, thunkAPI) => {
    const { updateInput } = queryVariables
    const contentId = thunkAPI.getState().content.entity?.id
    const snippets = thunkAPI.getState().snippets.entities
    const currentReusableContentBlocks = thunkAPI
      .getState()
      .content.entity?.contentBlocks.filter(({ isReusable }) => isReusable)

    // reusableContentBlocks's config is optional during update, we need to used current reusableContentBlocks's config if not provided
    const reusableContentBlocks: ReusableContentBlockUpdateDataInput[] =
      updateInput.map((reusableContentBlock) => {
        const {
          config: currentReusableContentBlockConfig,
          reusableContentBlockDraft,
        } =
          currentReusableContentBlocks?.find(
            ({ id, reusableContentBlockDraft }) =>
              id === reusableContentBlock.id ||
              reusableContentBlockDraft?.id === reusableContentBlock.id,
          ) || {}

        return {
          ...reusableContentBlock,
          config:
            reusableContentBlock.config ||
            reusableContentBlockDraft?.config ||
            currentReusableContentBlockConfig ||
            {},
        }
      })

    // safety check if provided references are materialized correctly
    const materializedReusableContentBlocks = reusableContentBlocks.map(
      (reusableContentBlock) =>
        materializeContentBlock({
          block: reusableContentBlock,
          snippets,
        }),
    )

    const { isValid, error } = validateContentBlocks({
      contentBlocks: materializedReusableContentBlocks,
    })

    if (!isValid) {
      errorNotification({
        content: 'Invalid reusable content blocks',
        log: {
          message:
            '[updateReusableContentBlocks]: Reusable content blocks data validation failed',
          data: {
            dataToValidate: materializedReusableContentBlocks,
            validationError: error,
            thunkQueryVariables: queryVariables,
          },
        },
      })

      return thunkReject({
        code: 'invalid_data',
        message: 'Invalid content data',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    const contentBlocksWithContentIdAndCleanedReferences =
      reusableContentBlocks?.map((reusableContentBlock) => ({
        ...reusableContentBlock,
        contentId,
        references: referencesCleanup({
          references: reusableContentBlock?.references || [],
          data: { config: reusableContentBlock?.config },
        }),
      }))

    return await asyncThunkFetcher({
      query: 'ReusableContentBlocksUpdateMutationDocument',
      selector: 'updateReusableContentBlocks',
      variables: {
        updateInput: contentBlocksWithContentIdAndCleanedReferences,
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      thunkAPI.dispatch(setContentPreferencesUserHasInteracted())
      return res
    })
  },
)

export const updateBlock = createGraphqlAsyncThunkByDocument<
  'ContentBlockUpdateMutationDocument',
  'updateContentBlock',
  UpdateContentBlockQueryVarsType
>()(
  'content/block/update',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const {
      blockCid,
      configPath,
      name,
      configValue,
      customConfigValue,
      referencesToProcess = [],
      referencesToReplace,
    } = queryVariables

    const block = thunkAPI
      .getState()
      .content.entity?.contentBlocks.find((block) => block.cid === blockCid)

    if (!block) {
      return thunkReject({
        code: 'data_not_found',
        message: 'Block not found',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    let blockConfig = block.config || {}
    if (configPath !== undefined && configValue !== undefined) {
      if (thunkOptions?.shouldReplaceValue) {
        blockConfig = assocJMESPath(configPath, configValue, block.config)
      } else {
        blockConfig = flattenUpdate(block.config, configValue, configPath)
      }

      if (thunkOptions?.shouldRemoveEmptyValues) {
        blockConfig = assocJMESPath(
          configPath,
          removeEmptyValues(search(blockConfig, configPath)),
          blockConfig,
        )
      }
    }

    const blockCustomConfig = customConfigValue || block.customConfig || {}

    const dataToValidate = {
      ...block,
      config: blockConfig,
    }

    const { isValid, error } = validateContentBlock(dataToValidate)

    if (!isValid) {
      errorNotification({
        content: 'Invalid block data',
        log: {
          message: `[updateBlock]: "${block.type}" block data validation failed`,
          data: {
            validationError: error,
            thunkQueryVariables: queryVariables,
            dataToValidate,
          },
        },
      })

      return thunkReject({
        code: 'invalid_data',
        message: 'Invalid block data',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    const { references: blockReferences } = block

    // process references
    let updatedReferences = blockReferences
    referencesToProcess.forEach(({ type, operation, data }) => {
      const referencePath = getReferencePath({
        configPath: data.path!,
        data: { config: blockConfig },
      })

      if (operation === 'delete') {
        updatedReferences = deleteReference({
          references: updatedReferences,
          path: referencePath,
        })
      }

      if (operation === 'add') {
        const appendReferencesMethod =
          APPEND_REFERENCE_METHOD_BY_TYPE_MAP[
            type as keyof typeof APPEND_REFERENCE_METHOD_BY_TYPE_MAP
          ]

        if (appendReferencesMethod) {
          updatedReferences = appendReferencesMethod({
            references: blockReferences,
            data: {
              id: data.id!,
              path: referencePath,
            },
          })
        }
      }
    })

    // final references cleanup
    updatedReferences = referencesCleanup({
      references: updatedReferences,
      data: { config: blockConfig },
    })

    return await asyncThunkFetcher({
      query: 'ContentBlockUpdateMutationDocument',
      selector: 'updateContentBlock',
      variables: {
        id: block.id,
        name: name,
        config: blockConfig,
        customConfig: blockCustomConfig,
        references:
          (referencesToReplace ? referencesToReplace : updatedReferences) || [],
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      thunkAPI.dispatch(setContentPreferencesUserHasInteracted())
      return res
    })
  },
)

export const moveBlockToIndex = createGraphqlAsyncThunkByDocument<
  'ContentBlockOrderUpdateMutationDocument',
  'updateContentBlocksOrder',
  UpdateContentBlockOrderQueryVarsType
>()('content/blocks/reorder', async ({ queryVariables }, thunkAPI) => {
  const { toIndex, toContentPropertyName, blockId, blockCid } = queryVariables
  const state = thunkAPI.getState()
  if (state.content.status !== 'succeeded') {
    return thunkReject({
      code: 'data_not_found',
      message: 'Blocks is undefined',
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  }
  const contentId = state.content.entity.id
  const renderableContentBlocks = state.content.entity.contentBlocks.filter(
    ({ isRenderable }) => isRenderable,
  )
  let id = blockId

  if (blockCid) {
    const block = state.content.entity.contentBlocks.find(
      ({ cid }) => cid === blockCid,
    )
    if (block) {
      id = block.id
    }
  }

  if (!renderableContentBlocks) {
    return thunkReject({
      code: 'data_not_found',
      message: 'Blocks is undefined',
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  }

  let toContentPropertyIndex = -1

  const blockIdsByContentPropertyName = renderableContentBlocks?.reduce<
    { contentPropertyName: string; blockIds: string[] }[]
  >((acc, block) => {
    // skip block we are moving, so we don't have to remove it afterwards
    if (block.id === id) {
      return acc
    }

    // find if we have already created the contentPropertyObject
    const contentPropertyIndex = acc.findIndex(
      (accVal) => accVal.contentPropertyName === block.contentPropertyName,
    )

    // If not create
    if (contentPropertyIndex === -1) {
      acc.push({
        contentPropertyName: block.contentPropertyName,
        blockIds: [block.id],
      })

      // save the index of toContentPropertyIndex, so we don't have to look for it later
      if (block.contentPropertyName === toContentPropertyName) {
        toContentPropertyIndex = acc.length - 1
      }
      // If yes just push id to the existing content property object
    } else {
      acc[contentPropertyIndex].blockIds.push(block.id)
    }
    return acc
  }, [])

  // add block id at toIndex position in toContentPropertyName

  blockIdsByContentPropertyName[toContentPropertyIndex].blockIds.splice(
    toIndex,
    0,
    id!,
  )

  return await asyncThunkFetcher({
    query: 'ContentBlockOrderUpdateMutationDocument',
    selector: 'updateContentBlocksOrder',
    variables: { orderData: blockIdsByContentPropertyName, contentId },
    thunkAPI,
    rejectQueries: ['CONTENT'],
  }).then((res) => {
    thunkAPI.dispatch(setContentPreferencesUserHasInteracted())

    return res
  })
})

export const selectAdjacentComponent =
  (direction: 'NEXT' | 'PREV') =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const contentState = getState().content
    const editorState = getState().editor
    const { selectedComponentId, selectedBlockId } = editorState.selectedEntity

    if (contentState.status !== 'succeeded') {
      return
    }

    const blockData = contentState.entity.contentBlocks.find(
      (block) => block.id === selectedBlockId,
    )
    if (!blockData || !blockData.type) {
      return
    }
    const blockComponentKeys = Object.keys(
      blocksMap[blockData.type].meta.component,
    )

    const selectCompontentAtIndex = (index: number) =>
      dispatch(
        selectContentBlock({
          queryVariables: {
            blockId: selectedBlockId,
            componentId: blockComponentKeys[index],
          },
        }),
      )

    const selectedCompontentIdx = blockComponentKeys.findIndex(
      (key) => selectedComponentId === key,
    )

    // TODO @tom manually skipping block component for now, pending block/componetns refactor
    if (direction === 'NEXT') {
      // at the end of the list
      if (selectedCompontentIdx + 1 >= blockComponentKeys.length) {
        return selectCompontentAtIndex(1)
      } else {
        return selectCompontentAtIndex(selectedCompontentIdx + 1)
      }
    }

    if (direction === 'PREV') {
      // at the start of the list
      if (selectedCompontentIdx === 1) {
        return selectCompontentAtIndex(blockComponentKeys.length - 1)
      } else {
        return selectCompontentAtIndex(selectedCompontentIdx - 1)
      }
    }
  }

export const selectAdjacentBlock =
  (direction: 'NEXT' | 'PREV') =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const contentState = getState().content
    const { selectedBlockId } = getState().editor.selectedEntity

    if (contentState.status !== 'succeeded') {
      return
    }
    const contentBlocks = contentState.entity.contentBlocks

    const selectBlockAtIndex = (index: number) =>
      dispatch(
        selectContentBlock({
          queryVariables: {
            blockId: contentBlocks[index].id,
            componentId: '',
          },
        }),
      )

    if (selectedBlockId) {
      const selectedBlockIndex = contentBlocks.findIndex(
        (block) => block.id === selectedBlockId,
      )

      if (direction === 'NEXT') {
        // at the end of the list
        if (selectedBlockIndex + 1 >= contentBlocks.length) {
          return selectBlockAtIndex(0)
        } else {
          return selectBlockAtIndex(selectedBlockIndex + 1)
        }
      }

      if (direction === 'PREV') {
        // at the start of the list
        if (selectedBlockIndex === 0) {
          return selectBlockAtIndex(contentBlocks.length - 1)
        } else {
          return selectBlockAtIndex(selectedBlockIndex - 1)
        }
      }
    } else {
      // no block selected
      if (direction === 'NEXT') {
        return selectBlockAtIndex(0)
      }
      if (direction === 'PREV') {
        return selectBlockAtIndex(contentBlocks.length - 1)
      }
    }
  }

export const selectAdjacentEntity =
  (direction: 'NEXT' | 'PREV') =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const contentState = getState().content
    const { selectedComponentId, selectedBlockId } =
      getState().editor.selectedEntity

    if (selectedComponentId) {
      // Traverse Components
      dispatch(selectAdjacentComponent(direction))
    } else {
      // Traverse Blocks
      dispatch(selectAdjacentBlock(direction))
    }
  }

export const deselectContentBlock =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().editor.selectedEntity.selectedBlockId) {
      dispatch(
        selectContentBlock({
          queryVariables: { blockId: '', componentId: '', frame: 'default' },
        }),
      )
    }
  }

export const deselectComponent =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { selectedBlockId, selectedComponentId } =
      getState().editor.selectedEntity
    if (selectedComponentId) {
      dispatch(
        selectContentBlock({
          queryVariables: {
            blockId: selectedBlockId,
            componentId: '',
          },
        }),
      )
    }
  }

export const deleteOrHide =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { selectedBlockId, selectedComponentId } =
      getState().editor.selectedEntity

    const contentBlocks = getState().content.entity?.contentBlocks || []

    // DELETE BLOCK
    if (selectedBlockId && !selectedComponentId) {
      const { isRenderable } =
        contentBlocks.find(({ id }) => selectedBlockId === id) || {}
      if (isRenderable) {
        dispatch(deleteBlock({ queryVariables: { id: selectedBlockId } }))
      }

      return
    }

    // HIDE COMPONENT
    if (selectedBlockId && selectedComponentId) {
      const contentState = getState().content
      if (contentState.status !== 'succeeded') {
        return
      }

      const blockData = contentState.entity.contentBlocks.find(
        (block) => block.id === selectedBlockId,
      )
      if (!blockData) {
        return
      }

      const componentMetaArrayPath = interleave(
        selectedComponentId.split('.'),
        'components',
      )

      const componentMetaJmesPath = componentMetaArrayPath
        .join('.')
        .replaceAll(/\[\d+\]/g, '')

      const componentMeta = search(
        blocksMap[blockData.type!]?.meta.component,
        componentMetaJmesPath,
      )

      const componentConfigPath = getComponentConfigPath({
        meta: blocksMap[blockData.type!]?.meta.component,
        selectedComponentId,
      })

      // Is hideable
      if (!componentMeta?.disableToggleShow) {
        dispatch(
          new UpdateBlockCommand({
            queryVariables: {
              blockCid: blockData.cid!,
              configPath: componentConfigPath,
              configValue: {
                ...search(blockData.config, componentConfigPath),
                show: false,
              },
            },
          }),
        )
      }
    }

    return
  }

export const selectBlockFirstChild =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const { selectedBlockId, selectedComponentId } = state.editor.selectedEntity

    // disregard invalid conditions
    if (
      !selectedBlockId ||
      selectedComponentId ||
      state.content.status !== 'succeeded'
    ) {
      return
    }

    const blockData = state.content.entity.contentBlocks.find(
      (block) => block.id === selectedBlockId,
    )
    if (!blockData || !blockData.type) {
      return
    }
    const blockComponents = blocksMap[blockData.type].meta.component
    const firstNonBlockComponent = Object.keys(blockComponents).find(
      (key) => key !== 'block',
    )

    if (firstNonBlockComponent) {
      dispatch(
        selectContentBlock({
          queryVariables: {
            blockId: selectedBlockId,
            componentId: firstNonBlockComponent,
          },
        }),
      )
    }
  }

export const selectContentBlock = createGraphqlAsyncThunkByDocument<
  'SetInteractionOnContentBlockDocument',
  'setInteractionOnContentBlock',
  SelectContentBlockQueryVarsType
>()('content/block/select', async ({ queryVariables }, thunkAPI) => {
  const { blockId, componentId, frame, triggeredBy, focusedFormMolecule } =
    queryVariables
  const state = thunkAPI.getState()
  const selectedBlockId = state.editor.selectedEntity.selectedBlockId
  // check if pending state => id === cid === blockId
  const isPendingState = Boolean(
    state.content.entity?.contentBlocks?.find(
      ({ id, cid }) => id === blockId && id === cid,
    ),
  )

  thunkAPI.dispatch(
    setSelectedEntity({
      selectedBlockId: blockId,
      selectedComponentId: componentId,
      selectedFrame: frame,
      triggeredBy,
      focusedFormMolecule,
    }),
  )

  if (!isPendingState) {
    // Blur action, remove interaction from previously selected block
    if (!blockId && selectedBlockId) {
      await asyncThunkFetcher({
        query: 'SetInteractionOnContentBlockDocument',
        selector: 'setInteractionOnContentBlock',
        variables: { id: selectedBlockId, action: 'BLUR' },
        thunkAPI,
      })
    }

    // set focus on newly selected block, blur the old
    if (blockId && blockId !== selectedBlockId) {
      const promises = []
      if (selectedBlockId) {
        // BLUR previous block
        promises.push(
          asyncThunkFetcher({
            query: 'SetInteractionOnContentBlockDocument',
            selector: 'setInteractionOnContentBlock',
            variables: { id: selectedBlockId, action: 'BLUR' },
            thunkAPI,
          }),
        )
      }

      // FOCUS new block
      promises.push(
        asyncThunkFetcher({
          query: 'SetInteractionOnContentBlockDocument',
          selector: 'setInteractionOnContentBlock',
          variables: { id: blockId, action: 'FOCUS' },
          thunkAPI,
        }),
      )

      // TODO if above mutations fail, it will not reject the selectContentBlock thunk
      // it is due to the fact that in order for thunk to be rejected we must return thunkApi.rejectWithValue
      // but we can't do that because we are have multiple async calls
      await Promise.all(promises)
    }
  }
})

export const blurSelectedContentBlock = createGraphqlAsyncThunkByDocument<
  'SetInteractionOnContentBlockDocument',
  'setInteractionOnContentBlock'
>()('content/block/blur', async (_, thunkAPI) => {
  const selectedBlockId =
    thunkAPI.getState().editor.selectedEntity.selectedBlockId

  if (!selectedBlockId) {
    return thunkReject({
      code: 'not_found',
      message: 'No block selected',
      thunkAPI,
      rejectQueries: [],
    })
  }

  return await asyncThunkFetcher({
    query: 'SetInteractionOnContentBlockDocument',
    selector: 'setInteractionOnContentBlock',
    variables: { id: selectedBlockId, action: 'BLUR' },
    thunkAPI,
    rejectQueries: ['CONTENT'],
  })
})

// ---------------
// Action creators
// ---------------

export const resolveUpdateContentBlock = (
  queryVariables: {
    blockId: string
    name?: string
    configValue?: Record<string, any>
    configPath?: string
    customConfigPath?: any
    customConfigValue?: any
    referencesToReplace?: ReferencesType
    referencesToProcess?: ReferencesToProcess
  },
  thunkOptions?: {
    shouldReplaceValue?: boolean
    shouldRemoveEmptyValues?: boolean
  },
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const block = getState().content.entity?.contentBlocks.find(
      (block) => block.id === queryVariables.blockId,
    )

    if (!block) {
      return
    }

    const isReusableContentBlock = block?.isReusable

    // Check the state and dispatch appropriate actions
    if (isReusableContentBlock) {
      dispatch(new UpdateReusableBlockCommand({ queryVariables, thunkOptions }))
    } else {
      dispatch(
        new UpdateBlockCommand({
          queryVariables: { ...queryVariables, blockCid: block.cid! },
          thunkOptions,
        }),
      )
    }
  }
}

// ---------------
// Reducer
// ---------------

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    resetContentState: () => {
      return initialState
    },
    wsUpdateWhiteboardContentContentBlocksEntity: (
      state,
      action: PayloadAction<WhiteboardContentEntitiesMetaUpdateResType>,
    ) => {
      if (!state.entity) {
        return
      }

      const updatedWhiteboardContentEntities = action.payload
      const updatedWhiteboardContentContentBlockEntities =
        updatedWhiteboardContentEntities.filter(
          ({ entity }) => entity === 'CONTENTBLOCK',
        )

      updatedWhiteboardContentContentBlockEntities.forEach((contentBlock) => {
        const existingContentBlockIndex = state.entity.contentBlocks.findIndex(
          (existingContentBlock) => existingContentBlock.id === contentBlock.id,
        )

        if (existingContentBlockIndex > -1) {
          const existingContentBlock =
            state.entity.contentBlocks[existingContentBlockIndex]

          state.entity.contentBlocks[existingContentBlockIndex] = {
            ...existingContentBlock,
            meta: {
              ...existingContentBlock.meta,
              whiteboard: {
                ...existingContentBlock.meta.whiteboard,
                ...contentBlock.payload,
              },
            },
          }
        }
      })
    },
    wsAddContentBlocks: (state, action: PayloadAction<BlockType[]>) => {
      if (!state.entity) {
        return
      }
      const addedContentBlocks = action.payload

      const nonRenderableContentBlocksLength =
        state.entity.contentBlocks.filter(
          ({ isRenderable }) => !isRenderable,
        ).length

      addedContentBlocks.forEach((block) => {
        const existingBlockIndex = state.entity.contentBlocks.findIndex(
          ({ cid }) => cid === block.cid,
        )
        if (existingBlockIndex > -1) {
          // the same block already exists
          const existingBlock = state.entity.contentBlocks[existingBlockIndex]
          const isPending = existingBlock.cid === existingBlock.id
          if (isPending) {
            state.entity.contentBlocks[existingBlockIndex] = block
          }
        } else {
          state.entity.contentBlocks.splice(
            block.order! + nonRenderableContentBlocksLength,
            0,
            block,
          )
        }
      })

      // update order
      state.entity.contentBlocks.forEach((block, idx) => {
        if (block.isRenderable) {
          block.order = idx - nonRenderableContentBlocksLength
        }
      })
    },
    wsUpdateContentBlocks: (state, action: PayloadAction<BlockType[]>) => {
      if (!state.entity) {
        return
      }
      const updatedContentBlocks = action.payload

      updatedContentBlocks.forEach((block) => {
        const blockId = block.id

        const blockIndex = state.entity.contentBlocks.findIndex(
          (block) => block.id === blockId,
        )

        state.entity.contentBlocks[blockIndex] = block
      })
    },
    wsDeleteContentBlocks: (state, action: PayloadAction<BlockType[]>) => {
      if (!state.entity) {
        return
      }
      const deletedContentBlocks = action.payload

      const nonRenderableContentBlocksLength =
        state.entity.contentBlocks.filter(
          ({ isRenderable }) => !isRenderable,
        ).length

      deletedContentBlocks.forEach((block) => {
        const blockId = block.id

        state.entity.contentBlocks = state.entity.contentBlocks.filter(
          (block) => block.id !== blockId,
        )
      })

      // update order
      state.entity.contentBlocks.forEach((block, idx) => {
        if (block.isRenderable) {
          block.order = idx - nonRenderableContentBlocksLength
        }
      })
    },
    wsSortContentBlock: (state, action: PayloadAction<BlockType[]>) => {
      if (!state.entity) {
        return
      }

      state.entity.contentBlocks = action.payload.reduce<BlockType[]>(
        (acc, { id, order }) => {
          const block = state.entity.contentBlocks.find(
            (findBlock) => findBlock.id === id,
          )

          if (block) {
            return [...acc, { ...block, order }]
          }

          return acc
        },
        [],
      )
    },
    wsUpdateBlockFocusedBy: (
      state,
      action: PayloadAction<Pick<BlockType, 'id' | 'cid' | 'focusedByUser'>>,
    ) => {
      if (!state.entity) {
        return
      }

      const blockIndex = state.entity.contentBlocks.findIndex(
        (block) => block.id === action.payload.id,
      )

      if (blockIndex > -1) {
        state.entity.contentBlocks[blockIndex].focusedByUser =
          action.payload.focusedByUser
      }
    },
    wsUpdateReusableContentBlocks: (
      state,
      action: PayloadAction<ReusableContentBlockOutput[]>,
    ) => {
      if (!state.entity) {
        return
      }

      const updatedReusableContentBlocks = action.payload

      updatedReusableContentBlocks.forEach((reusableContentBlock) => {
        const { contextContentBlocks, draftReusableContentBlock } =
          reusableContentBlock

        if (!contextContentBlocks) {
          return
        }

        const nonRenderableContentBlocksLength =
          state.entity.contentBlocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

        contextContentBlocks.forEach((reusableContentBlock, index) => {
          const existingBlockIndex = state.entity.contentBlocks.findIndex(
            (block) => block.cid === reusableContentBlock.cid,
          )

          // make as reusable/update/merge/delete draft
          if (existingBlockIndex > -1) {
            state.entity.contentBlocks[existingBlockIndex] = {
              ...contextContentBlocks[index],
              reusableContentBlockDraft: draftReusableContentBlock,
            }
            return
          }

          // assign reusable content block
          state.entity.contentBlocks.splice(
            reusableContentBlock.order! + nonRenderableContentBlocksLength,
            0,
            {
              ...reusableContentBlock,
              reusableContentBlockDraft: draftReusableContentBlock,
            },
          )
        })
        state.entity.contentBlocks.forEach((block, idx) => {
          if (block.isRenderable) {
            block.order = idx - nonRenderableContentBlocksLength
          }
        })
      })
    },
    wsUpdateContent: (state, action: PayloadAction<ContentType>) => {
      if (!state.entity) {
        return
      }
      const { data, references, preferences } = action.payload
      state.entity.data = data
      state.entity.references = references
      state.entity.preferences = preferences
    },
    setAIPrompt: (state, action: PayloadAction<string>) => {
      if (!state.entity) {
        return
      }
      state.entity.preferences = {
        ...state.entity.preferences,
        AIPrompt: action.payload,
      }
    },
    clearBlocks: (state) => {
      if (!state.entity) {
        return
      }
      state.entity.contentBlocks = []
    },
    wsReplaceBlocks: (state, action: PayloadAction<BlockType[]>) => {
      if (!state.entity) {
        return
      }

      state.entity.contentBlocks = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase('USER:LOGOUT', () => {
        return initialState
      })
      .addCase(fetchContent.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // We are overriding payload data type because we are sure `payload.data` will be provided,
        // otherwise it would be rejected in fetchContent thunk
        const payload = action.payload as ContentType
        state.entity = {
          ...payload,
          contentBlocks: payload.contentBlocks,
        } as ContentType
      })
      .addCase(fetchContent.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload?.message || null
      })
      .addCase(
        setContentPreferencesUserHasInteracted.fulfilled,
        (state, action) => {
          if (state.status === 'succeeded' && action?.payload) {
            const { preferences } = action.payload
            state.entity.preferences = preferences
          }
        },
      )
      .addCase(deleteWhiteboardContentEntities.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const queryVariables = action.meta.arg.queryVariables
          const { deleteInput } = queryVariables

          const whiteboardContentContentBlocksIdsToDelete = deleteInput.reduce<
            string[]
          >((acc, { entity, id }) => {
            if (entity === 'CONTENTBLOCK') {
              acc.push(id)
            }
            return acc
          }, [])

          state.entity.contentBlocks = state.entity.contentBlocks.filter(
            ({ id }) => !whiteboardContentContentBlocksIdsToDelete.includes(id),
          )
        }
      })
      .addCase(updateWhiteboardContentEntitiesMeta.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const updatedWhiteboardContentEntities =
            action.meta.arg.queryVariables.updateInput
          const updatedWhiteboardContentContentBlockEntities =
            updatedWhiteboardContentEntities.filter(
              ({ entity }) => entity === 'CONTENTBLOCK',
            )

          updatedWhiteboardContentContentBlockEntities.forEach(
            (contentBlock) => {
              const existingContentBlockIndex =
                state.entity.contentBlocks.findIndex(
                  (existingContentBlock) =>
                    existingContentBlock.id === contentBlock.id,
                )

              if (existingContentBlockIndex > -1) {
                const existingContentBlock =
                  state.entity.contentBlocks[existingContentBlockIndex]

                state.entity.contentBlocks[existingContentBlockIndex] = {
                  ...existingContentBlock,
                  meta: {
                    ...existingContentBlock.meta,
                    whiteboard: {
                      ...existingContentBlock.meta.whiteboard,
                      ...contentBlock.payload,
                    },
                  },
                }
              }
            },
          )
        }
      })
      // TODO extract dua to identical resolving with duplicateContentBlock
      .addCase(addBlock.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const { position, isRenderable, cid, ...blockData } =
            action.meta.arg.queryVariables

          const blocks = state.entity.contentBlocks

          const nonRenderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

          const renderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => isRenderable,
          ).length

          const order = position ?? renderableContentBlocksLength

          // We don't want to add pending block if there is no way to identify it in the future
          if (!cid) {
            return
          }

          if (state.entity?.preferences?.userHasInteracted) {
            state.entity.preferences.userHasInteracted = true
          }

          const newBlockData = {
            ...blockData,
            id: cid,
            cid,
            customConfig: {},
            isRenderable,
          }

          if (!isRenderable) {
            state.entity.contentBlocks = [
              { ...newBlockData, order: -1 },
              ...state.entity.contentBlocks,
            ]
            return
          }

          state.entity.contentBlocks.splice(
            order + nonRenderableContentBlocksLength,
            0,
            {
              ...newBlockData,
              order,
            },
          )

          state.entity.contentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nonRenderableContentBlocksLength
            }
          })
        }
      })
      // TODO extract dua to identical resolving with duplicateContentBlock
      .addCase(addBlock.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const { cid } = action.meta.arg.queryVariables

          const blocks = state.entity.contentBlocks
          const { order } = action.payload

          const nonRenderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

          const existingBlockIndex = state.entity.contentBlocks.findIndex(
            (block) => block.cid === cid,
          )

          // if we have pending block replace it, otherwise add as new
          if (existingBlockIndex > -1) {
            const existingBlock = state.entity.contentBlocks[existingBlockIndex]
            const isPending = existingBlock.cid === existingBlock.id
            if (isPending) {
              state.entity.contentBlocks[existingBlockIndex] = action.payload
            }
          } else {
            // non renderable
            if (order === -1) {
              state.entity.contentBlocks = [
                action.payload,
                ...state.entity.contentBlocks,
              ]
              return
            } else {
              state.entity.contentBlocks.splice(
                order! + nonRenderableContentBlocksLength,
                0,
                action.payload,
              )
            }
          }
          // update order
          state.entity.contentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nonRenderableContentBlocksLength
            }
          })
        }
      })
      .addCase(addBlocks.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const { position, contentBlocksData, contentPropertyName } =
            action.meta.arg.queryVariables

          const blocks = state.entity.contentBlocks

          const nonRenderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

          const order = position ?? state.entity.contentBlocks.length

          // We don't want to add pending block if there is no way to identify it in the future
          const contentBlocksToAdd = contentBlocksData
            .filter(({ cid }) => Boolean(cid))
            .map((blockData, index) => ({
              ...blockData,
              id: blockData.cid!,
              contentPropertyName,
              customConfig: {},
              order: order + index,
            }))

          if (contentBlocksToAdd.length === 0) {
            return
          }

          state.entity.contentBlocks.splice(
            order + nonRenderableContentBlocksLength,
            0,
            ...contentBlocksToAdd,
          )

          state.entity.contentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nonRenderableContentBlocksLength
            }
          })
        }
      })

      .addCase(addBlocks.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const newContentBlocks = action.payload

          const blocks = state.entity.contentBlocks

          const nonRenderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

          newContentBlocks.forEach((contentBlock) => {
            const existingBlockIndex = state.entity.contentBlocks.findIndex(
              (block) => block.cid === contentBlock.cid,
            )

            if (existingBlockIndex > -1) {
              const existingBlock =
                state.entity.contentBlocks[existingBlockIndex]
              const isPending = existingBlock.cid === existingBlock.id
              if (isPending) {
                state.entity.contentBlocks[existingBlockIndex] = contentBlock
              }
            } else {
              state.entity.contentBlocks.splice(
                contentBlock.order! + nonRenderableContentBlocksLength,
                0,
                contentBlock,
              )
            }
          })

          // update order
          state.entity.contentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nonRenderableContentBlocksLength
            }
          })
        }
      })
      .addCase(duplicateContentBlock.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const {
            cid,
            position: order,
            id: requestedContentBlockId,
          } = action.meta.arg.queryVariables

          const blocks = state.entity.contentBlocks

          const nonRenderableContentBlocksLength =
            blocks.filter(({ isRenderable }) => !isRenderable).length +
            (order === -1 ? 1 : 0)

          // We don't want to add pending block if there is no way to identify it in the future
          if (!cid) {
            return
          }

          const requestedContentBlock = state.entity.contentBlocks.find(
            ({ id }) => id === requestedContentBlockId,
          )

          if (requestedContentBlock) {
            state.entity.contentBlocks.splice(
              order + nonRenderableContentBlocksLength,
              0,
              {
                ...requestedContentBlock,
                order,
                id: cid,
                cid,
                customConfig: {},
              },
            )
          }

          state.entity.contentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nonRenderableContentBlocksLength
            }
          })
        }
      })
      .addCase(duplicateContentBlock.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const { cid } = action.meta.arg.queryVariables

          const existingBlockIndex = state.entity.contentBlocks.findIndex(
            (block) => block.cid === cid,
          )

          const blocks = state.entity.contentBlocks

          const nonRenderableContentBlocksLength =
            blocks.filter(({ isRenderable }) => !isRenderable).length +
            (action.payload?.order! === -1 ? 1 : 0)

          // if we have pending block replace it, otherwise add as new
          if (existingBlockIndex > -1) {
            const existingBlock = state.entity.contentBlocks[existingBlockIndex]
            const isPending = existingBlock.cid === existingBlock.id
            if (isPending) {
              state.entity.contentBlocks[existingBlockIndex] = action.payload
            }
          } else {
            state.entity.contentBlocks.splice(
              action.payload.order! + nonRenderableContentBlocksLength,
              0,
              action.payload,
            )
          }
          // update order
          state.entity.contentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nonRenderableContentBlocksLength
            }
          })
        }
      })
      .addCase(deleteReusableContentBlockDraft.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const { contextContentBlocks } = action.payload

          contextContentBlocks
            ?.map(({ id }) => id)
            .forEach((blockId) => {
              const blockIndex = state.entity.contentBlocks.findIndex(
                (block) => block.id === blockId,
              )

              if (blockIndex > -1) {
                state.entity.contentBlocks[
                  blockIndex
                ].reusableContentBlockDraft = null
              }
            })
        }
      })
      .addCase(mergeReusableContentBlockDraft.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const {
            contextContentBlocks,
            issuedReusableContentBlock: {
              id: issuedReusableContentBlockId,
              ...restIssuedReusableContentBlock
            } = {},
          } = action.payload

          contextContentBlocks
            ?.map(({ id }) => id)
            .forEach((blockId) => {
              const blockIndex = state.entity.contentBlocks.findIndex(
                (block) => block.id === blockId,
              )

              state.entity.contentBlocks[blockIndex] = {
                ...state.entity.contentBlocks[blockIndex],
                ...restIssuedReusableContentBlock,
                reusableContentBlockDraft: null,
              }
            })
        }
      })
      .addCase(assignReusableContentBlock.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const { cid } = action.meta.arg.queryVariables

          const blocks = state.entity.contentBlocks

          const nonRenderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

          const existingBlockIndex = state.entity.contentBlocks.findIndex(
            (block) => block.cid === cid,
          )

          // if we have pending block replace it, otherwise add as new
          if (existingBlockIndex > -1) {
            return
          } else {
            state.entity.contentBlocks.splice(
              action.payload.order! + nonRenderableContentBlocksLength,
              0,
              action.payload,
            )
          }

          state.entity.contentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nonRenderableContentBlocksLength
            }
          })
        }
      })
      .addCase(deleteBlock.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const { id } = action.meta.arg.queryVariables

          const nonRenderableContentBlocksLength =
            state.entity.contentBlocks.filter(
              ({ isRenderable }) => !isRenderable,
            ).length

          state.entity.contentBlocks = state.entity.contentBlocks.filter(
            (block) => block.id !== id,
          )
          // update order
          state.entity.contentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nonRenderableContentBlocksLength
            }
          })
        }
      })
      .addCase(deleteBlocks.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const { ids } = action.meta.arg.queryVariables

          const nonRenderableContentBlocksLength =
            state.entity.contentBlocks.filter(
              ({ isRenderable }) => !isRenderable,
            ).length

          state.entity.contentBlocks = state.entity.contentBlocks.filter(
            (block) => !ids?.includes(block.id),
          )

          // update order
          state.entity.contentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nonRenderableContentBlocksLength
            }
          })
        }
      })
      .addCase(moveBlockToIndex.pending, (state, action) => {
        const blocks = state.entity?.contentBlocks
        const { blockId, toContentPropertyName, toIndex, blockCid } =
          action.meta.arg.queryVariables

        if (!blocks) {
          return
        }

        let id = blockId

        if (state.status !== 'succeeded') {
          return
        }

        if (blockCid) {
          const block = state.entity.contentBlocks.find(
            ({ cid }) => cid === blockCid,
          )
          if (block) {
            id = block.id
          }
        }

        const nonRenderableContentBlocksLength = blocks.filter(
          ({ isRenderable }) => !isRenderable,
        ).length

        const renderableContentBlocks = blocks.filter(
          ({ isRenderable }) => isRenderable,
        )

        const blockIndex = renderableContentBlocks.findIndex(
          (block) => block.id === id,
        )
        const block = renderableContentBlocks[blockIndex]

        const targetIndex = renderableContentBlocks.findIndex(
          (block) =>
            block.contentPropertyName === toContentPropertyName &&
            block.order === toIndex,
        )

        // remove element from the blocks
        blocks.splice(blockIndex + nonRenderableContentBlocksLength, 1)

        // add element to position
        blocks.splice(targetIndex + nonRenderableContentBlocksLength, 0, {
          ...block,
          contentPropertyName: toContentPropertyName,
        })

        blocks.forEach((block, idx) => {
          if (block.isRenderable) {
            block.order = idx - nonRenderableContentBlocksLength
          }
        })
      })
      .addCase(moveBlockToIndex.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        state.entity.contentBlocks = action.payload.contentBlocks.map(
          (newBlockData) => {
            const oldBlockData = state.entity?.contentBlocks.find(
              (oldBlock) => oldBlock.id === newBlockData.id,
            )
            return { ...oldBlockData, ...newBlockData }
          },
        )
      })
      .addCase(updateContentBlocks.pending, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        state.requests.updateBlocksRequestId = action.meta.requestId

        const updatedContentBlocks = action.meta.arg.queryVariables.updateInput

        updatedContentBlocks.forEach((contentBlock) => {
          const existingContentBlockIndex =
            state.entity?.contentBlocks.findIndex(
              (existingContentBlock) =>
                existingContentBlock.id === contentBlock.id,
            )

          if (existingContentBlockIndex > -1) {
            const existingContentBlock =
              state.entity.contentBlocks[existingContentBlockIndex]

            state.entity.contentBlocks[existingContentBlockIndex] = {
              ...existingContentBlock,
              ...contentBlock,
            }
          }
        })
      })
      .addCase(updateContentBlocks.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        if (action.meta.requestId !== state.requests.updateBlocksRequestId) {
          return
        }

        const updatedBlocks = action.payload || []

        updatedBlocks.forEach((contentBlock) => {
          const blockIndex = state.entity.contentBlocks.findIndex(
            (block) => block.id === contentBlock.id,
          )

          if (blockIndex > -1) {
            state.entity.contentBlocks[blockIndex] = contentBlock
          }
        })
      })
      .addCase(updateReusableContentBlocks.pending, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        state.requests.updateReusableBlocksRequestId = action.meta.requestId
      })
      .addCase(updateReusableContentBlocks.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        if (
          action.meta.requestId !== state.requests.updateReusableBlocksRequestId
        ) {
          return
        }

        const updatedReusableContentBlocks = action.payload || []

        updatedReusableContentBlocks.forEach((reusableContentBlock) => {
          const { contextContentBlocks, draftReusableContentBlock } =
            reusableContentBlock

          contextContentBlocks
            ?.map(({ id }) => id)
            .forEach((blockId) => {
              const blockIndex = state.entity.contentBlocks.findIndex(
                (block) => block.id === blockId,
              )

              if (blockIndex > -1) {
                const contentBlock = state.entity.contentBlocks[blockIndex]
                state.entity.contentBlocks[blockIndex] = {
                  ...contentBlock,
                  reusableContentBlockDraft: draftReusableContentBlock,
                }
              }
            })
        })
      })
      .addCase(updateBlock.pending, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        state.requests.updateBlockRequestId = action.meta.requestId

        const { queryVariables, thunkOptions } = action.meta.arg

        const { blockCid, configPath, configValue, customConfigValue } =
          queryVariables
        const blockIndex = state.entity.contentBlocks.findIndex(
          (block) => block.cid === blockCid,
        )
        let block = state.entity.contentBlocks[blockIndex]

        let blockConfig = block.config || {}
        if (configValue !== undefined && configPath) {
          if (thunkOptions?.shouldReplaceValue) {
            blockConfig = assocJMESPath(configPath, configValue, block.config)
          } else {
            blockConfig = flattenUpdate(block.config, configValue, configPath)
          }

          if (thunkOptions?.shouldRemoveEmptyValues) {
            blockConfig = assocJMESPath(
              configPath,
              removeEmptyValues(search(blockConfig, configPath)),
              blockConfig,
            )
          }
        }

        const cleanedReferences = referencesCleanup({
          references: block.references,
          data: { config: blockConfig },
        })

        const blockCustomConfig = customConfigValue || block.customConfig || {}

        const { isValid } = validateContentBlock({
          ...block,
          config: blockConfig,
        })

        if (!isValid) {
          return
        }

        // TODO @tom references

        state.entity.contentBlocks[blockIndex] = {
          ...block,
          config: blockConfig,
          customConfig: blockCustomConfig,
          references: cleanedReferences,
        }
      })
      .addCase(updateBlock.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        if (action.meta.requestId !== state.requests.updateBlockRequestId) {
          return
        }

        const { blockCid } = action.meta.arg.queryVariables

        const blockIndex = state.entity.contentBlocks.findIndex(
          (block) => block.cid === blockCid,
        )

        state.entity.contentBlocks[blockIndex] = action.payload
      })
      .addCase(updateReusableContentBlock.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        const updatedReusableContentBlocks = action.payload

        updatedReusableContentBlocks.forEach(
          ({ contextContentBlocks, draftReusableContentBlock }) => {
            contextContentBlocks
              ?.map(({ id }) => id)
              .forEach((blockId) => {
                const blockIndex = state.entity.contentBlocks.findIndex(
                  (block) => block.id === blockId,
                )

                if (blockIndex > -1) {
                  const contentBlock = state.entity.contentBlocks[blockIndex]
                  state.entity.contentBlocks[blockIndex] = {
                    ...contentBlock,
                    reusableContentBlockDraft: draftReusableContentBlock,
                  }
                }
              })
          },
        )
      })
      .addCase(makeContentBlockReusable.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        const { id, ...restActionPayload } = action.payload

        const blockIndex = state.entity.contentBlocks.findIndex(
          (block) => block.id === id,
        )

        state.entity.contentBlocks[blockIndex] = {
          ...state.entity.contentBlocks[blockIndex],
          ...restActionPayload,
        }
      })
      .addCase(setContentBlocksAsRenderable.pending, (state, action) => {
        if (!state.entity) {
          return
        }

        const blocks = state.entity.contentBlocks

        let nonRenderableContentBlocksLength = blocks?.filter(
          ({ isRenderable }) => !isRenderable,
        ).length

        const { queryVariables } = action.meta.arg
        const { setRenderableInput: blocksToSetAsRenderable } = queryVariables

        blocksToSetAsRenderable.forEach(({ id, position }) => {
          const blockIndex = blocks.findIndex((block) => block.id === id)
          const block = blocks[blockIndex]

          blocks.splice(blockIndex, 1)
          // skip non renderable blocks and place new block within renderable ones
          blocks.splice(nonRenderableContentBlocksLength - 1 + position, 0, {
            ...block,
            isRenderable: true,
          })
        })

        nonRenderableContentBlocksLength = blocks?.filter(
          ({ isRenderable }) => !isRenderable,
        ).length

        blocks.forEach((block, idx) => {
          // ignore non renderable blocks with order -1
          if (block.isRenderable) {
            block.order = idx - nonRenderableContentBlocksLength
          }
        })
      })
      .addCase(setContentBlocksAsRenderable.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        const blocks = state.entity.contentBlocks

        const newRenderableContentBlocks = action.payload

        newRenderableContentBlocks.forEach((renderableContentBlock) => {
          const blockIndex = blocks.findIndex(
            (block) => block.id === renderableContentBlock.id,
          )
          const block = blocks[blockIndex]

          blocks[blockIndex] = {
            ...block,
            ...renderableContentBlock,
          }
        })
      })
      .addCase(setContentBlocksAsNonRenderable.pending, (state, action) => {
        if (!state.entity) {
          return
        }

        const blocks = state.entity.contentBlocks

        const { queryVariables } = action.meta.arg
        const { setNonRenderableInput: blocksToSetAsNonRenderable } =
          queryVariables

        blocksToSetAsNonRenderable.forEach(({ id, meta }) => {
          const blockIndex = blocks.findIndex((block) => block.id === id)
          const block = blocks[blockIndex]

          block.isRenderable = false
          block.order = -1
          block.meta = meta
        })

        state.entity.contentBlocks = blocks.sort((a, b) => a.order - b.order)

        const nonRenderableContentBlocksLength = blocks.filter(
          ({ isRenderable }) => !isRenderable,
        ).length

        blocks.forEach((block, idx) => {
          if (block.isRenderable) {
            block.order = idx - nonRenderableContentBlocksLength
          }
        })
      })
      .addCase(setContentBlocksAsNonRenderable.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        const blocks = state.entity.contentBlocks

        const newNonRenderableContentBlocks = action.payload

        newNonRenderableContentBlocks.forEach((renderableContentBlock) => {
          const blockIndex = blocks.findIndex(
            (block) => block.id === renderableContentBlock.id,
          )
          const block = blocks[blockIndex]

          blocks[blockIndex] = {
            ...block,
            ...renderableContentBlock,
          }
        })
      })
      .addCase(updateContentPath.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(updateContentPath.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(updateContentPath.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(updateContent.pending, (state, action) => {
        if (!state.entity) {
          return
        }
        //prevent old requests overwriting the current one
        state.requests.updateContentRequestId = action.meta.requestId

        const { queryVariables } = action.meta.arg
        const { id, references } = queryVariables
        const data = queryVariables?.data || state.entity?.data

        state.entity = {
          ...state.entity,
          data,
          id,
          references,
        }
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        if (action.meta.requestId !== state.requests.updateContentRequestId) {
          return
        }
        const { data, references, preferences } = action.payload

        state.entity.data = data
        state.entity.references = references
        state.entity.preferences = preferences
      })
      .addCase(updateContentData.pending, (state, action) => {
        if (!state.entity) {
          return
        }
        //prevent old requests overwriting the current one
        state.requests.updateContentDataRequestId = action.meta.requestId

        const { queryVariables, thunkOptions } = action.meta.arg
        const { configPath, configValue } = queryVariables
        let updatedData = state.entity.data

        if (thunkOptions?.shouldReplaceValue) {
          updatedData = assocJMESPath(
            configPath,
            configValue,
            updatedData,
          ) as ContentDataType
        } else {
          updatedData = mergeDeepRight({ config: configValue }, updatedData)
        }

        const { isValid } = validateContent({ data: updatedData })

        if (!isValid) {
          return
        }

        state.entity.data = updatedData
      })
      .addCase(updateContentData.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        if (
          action.meta.requestId !== state.requests.updateContentDataRequestId
        ) {
          return
        }
        const { data, references, preferences, updatedAt } = action.payload

        state.entity.data = data
        state.entity.references = references
        state.entity.preferences = preferences
        state.entity.updatedAt = updatedAt
      })
      .addCase(requestContentBlocksByAiV2.pending, (state) => {
        if (!state.entity) return
        state.entity.contentBlocks = []
      })
      .addCase(requestContentBlocksByAiV2.fulfilled, (state) => {
        if (!state.entity) return
        state.entity.preferences.isAIGeneratedContent = true
      })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useContentDispatch: () => AppDispatch = useDispatch
export const useContentSelector: TypedUseSelectorHook<RootState> = useSelector

// Action creators are generated for each case reducer function
export const {
  resetContentState,
  wsAddContentBlocks,
  wsUpdateContentBlocks,
  wsDeleteContentBlocks,
  wsSortContentBlock,
  wsUpdateWhiteboardContentContentBlocksEntity,
  wsUpdateReusableContentBlocks,
  wsUpdateBlockFocusedBy,
  wsUpdateContent,
  wsReplaceBlocks,
  setAIPrompt,
  clearBlocks,
} = contentSlice.actions

export default contentSlice.reducer
