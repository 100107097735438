import React from 'react'
import TestimonialCarousel2 from './TestimonialCarousel2'
import { testimonialCarousel2DefaultConfig } from './testimonialCarousel2Meta'

export function TestimonialCarousel2Preview(config: any) {
  const defaultData =
    Object.keys(config).length !== 0
      ? config
      : testimonialCarousel2DefaultConfig

  return <TestimonialCarousel2 {...defaultData} />
}
