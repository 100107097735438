import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { testimonialCarousel2DefaultConfig } from './testimonialCarousel2Meta'
import Text from '../../../components/Text/Text'
import type { TestimonialCarousel2Block } from '@sceneio/schemas/lib/types'
import { TestimonialCard } from '../TestimonialVariant1/components/TestimonialCard'
import SliderVariant3 from '../../../components/Slider/SliderVariant3/SliderVariant3'

function TestimonialCarousel2({
  id,
  components: { block, tagline, heading, text, slider },
  preview,
  editor,
}: TestimonialCarousel2Block = testimonialCarousel2DefaultConfig) {
  const { className, ...restBlockProps } = block || {}

  const testimonialClass = clsx('sc-testimonial-carousel-2', className)

  return (
    <Block
      className={testimonialClass}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <Text
        {...tagline}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'tagline',
        }}
        cid={resolveComponentId(id, 'tagline')}
      />
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <SliderVariant3
        {...slider}
        preview={preview}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'slider',
        }}
        className="sc-testimonial-carousel-2__slider"
        components={{
          ...slider.components,
          slides: {
            ...slider.components.slides,
            items: slider.components.slides.items.map((slide, idx) => {
              return (
                <TestimonialCard
                  key={slide.id}
                  config={slide}
                  blockId={id}
                  editor={{
                    ...editor?.component,
                    blockId: id,
                    id: `slider.slides.items[${idx}]`,
                  }}
                />
              )
            }),
          },
        }}
      />
    </Block>
  )
}

export const testimonialCarousel2Plugins = {
  js: ['animation', 'slider'],
  css: ['slider'],
}
export default TestimonialCarousel2
