import { WsProvider } from '@sceneio/graphql-subscriptions'
// import LogRocket from 'logrocket'
import { lazy, Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { componentLoader } from './helpers/componentLoader'
import { useUserStore } from './hooks/store/storeHooks'
import LoadingPage from './pages/loading/LoadingPage'
import Playground from './pages/playground/PlaygroundPage'
import { useGoogleAnalytics } from './externals/useGoogleAnalytics'
// import TwicPics react components
import { installTwicPics } from '@twicpics/components/react'
import { posthog } from 'posthog-js'
import { setUser } from '@sentry/react'
import { useGTMScript } from './externals/useGTMScript'

// TwicPics Components configuration (see Setup Options)
const twicPicsDomain = import.meta.env.VITE_TWICPICS_DOMAIN
const logRocketToken = import.meta.env.VITE_LOG_ROCKET_TOKEN
const posthogToken = import.meta.env.VITE_POSTHOG_TOKEN
const sentryDSN = import.meta.env.VITE_SENTRY_DSN
const backendUrl = import.meta.env.VITE_BACKEND_URL

installTwicPics({
  // domain is mandatory
  domain: `https://${twicPicsDomain}`,
})

// if (logRocketToken) {
//   LogRocket.init(logRocketToken, {
//     console: {
//       shouldAggregateConsoleErrors: true,
//     },
//   })
// }

// Login
const LoginPage = lazy(() =>
  componentLoader(() => import('./pages/auth/LoginPage')),
)

const AuthenticatedLayout = lazy(() =>
  componentLoader(() => import('./pages/AuthenticatedLayout')),
)

function App() {
  const { isLoggedIn, id, name, email, token } = useUserStore()
  useGoogleAnalytics()
  useGTMScript()

  useEffect(() => {
    if (isLoggedIn) {
      if (!email || !id) {
        return
      }

      if (posthogToken) {
        posthog.identify(id, {
          email,
          name: name ?? '',
        })
      }
      if (sentryDSN) {
        setUser({ id, email, username: name ?? '' })
      }
      // if (logRocketToken) {
      //   LogRocket.identify(id, {
      //     name: name ?? '',
      //     email,
      //   })
      // }
    }
  }, [isLoggedIn])

  if (!isLoggedIn) {
    return (
      <>
        <Suspense fallback={null}>
          <Routes>
            <Route path="/*" element={<LoginPage />} />
          </Routes>
        </Suspense>
      </>
    )
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route
          path="/*"
          element={
            <WsProvider backendUrl={backendUrl} token={token!}>
              <AuthenticatedLayout />
            </WsProvider>
          }
        />
      </Routes>
    </Suspense>
  )
}

export default App
