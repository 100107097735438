import React from 'react'
import clsx from 'clsx'
import { isEmpty } from 'ramda'
import { getRootLevelCssVariables } from '../../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../../tools/cssVariables/getShadowCssVariables'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { BreakpointEnum } from '../../Image/constants/breakpoints'
import { resolveBreakpointGridClasses } from './helpers/resolveBreakpointGridClasses'
import { resolveDefaultGridClasses } from './helpers/resolveDefaultGridClasses'
import { MasonryGridComponent } from '@sceneio/schemas/lib/types'
import type { JSX } from 'react'

const LeftToRightMasonryGrid = ({
  columns,
  margin,
  padding,
  boxShadow,
  border,
  gap,
  items = [],
  className,
  columnClassName,
  show = true,
  editor,
  style,
}: MasonryGridComponent) => {
  if (isEmpty(items) || !show) {
    return null
  }

  const gridClass = clsx(
    'sc-masonry-ltr-grid',
    className,
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
  )

  const columnsConfig = columns?.data

  const createColumns = (breakpointCols: number) => {
    const columnItems: Record<number, JSX.Element[]> = {}

    // Distribute items into columns
    items.forEach((item, index) => {
      const columnIndex = index % breakpointCols
      columnItems[columnIndex] = [
        ...(columnItems[columnIndex] || []),
        <React.Fragment key={index}>{item}</React.Fragment>,
      ]
    })

    return Array.from({ length: breakpointCols }, (_, i) => (
      <div
        className={clsx('sc-masonry-ltr-grid__column', columnClassName)}
        key={i}
      >
        {columnItems[i]}
      </div>
    ))
  }

  const extractColumnBreakpoints = () => {
    const regex = /--columns-(\w+)/
    return Object.keys(columnsConfig || {})
      .map((key) => key.match(regex))
      .filter(Boolean)
      .map((match) => match?.[1])
  }

  return (
    <div
      data-component-id={editor?.id}
      className={gridClass}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            margin,
            padding,
            border,
            gap,
            columns,
          },
          prefix: 'masonry-grid',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'masonry-grid',
        }),
      }}
    >
      {columnsConfig && Object.keys(columnsConfig).length && (
        <>
          {Object.values(columnsConfig).map((config, idx) => {
            const value = config
            const bp = extractColumnBreakpoints()[idx] || 'default'
            return (
              <div
                className={clsx('sc-masonry-ltr-grid__container', {
                  [resolveBreakpointGridClasses({
                    breakpoint: bp as BreakpointEnum,
                    elementClassName: 'sc-masonry-ltr-grid__container',
                  })]: bp !== 'default',
                  [resolveDefaultGridClasses({
                    elementClassName: 'sc-masonry-ltr-grid__container',
                    breakpointsArr:
                      extractColumnBreakpoints() as BreakpointEnum[],
                  })]: bp === 'default',
                })}
                key={bp}
              >
                {createColumns(value)}
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}

export default LeftToRightMasonryGrid
