import { useState, useRef, useMemo } from 'react'
import { LexicalEditor } from 'lexical'
import { $getSelection, $INTERNAL_isPointSelection } from 'lexical'
import { $patchStyleText } from '@lexical/selection'
import { Select, SelectPopover, SelectProvider } from '@ariakit/react'
import { Icon } from '@sceneio/ui-icons'
import { fontsConfig } from '@sceneio/config-fonts'
import type { FontWeightType } from '@sceneio/config-fonts/lib/fonts'

const FONT_WEIGHT_OPTIONS = [
  { value: '100', label: '100 - Thin' },
  { value: '200', label: '200 - Extra Light' },
  { value: '300', label: '300 - Light' },
  { value: '400', label: '400 - Regular' },
  { value: '500', label: '500 - Medium' },
  { value: '600', label: '600 - Semi Bold' },
  { value: '700', label: '700 - Bold' },
  { value: '800', label: '800 - Extra Bold' },
  { value: '900', label: '900 - Black' },
]

interface ToolbarFontWeightSelectProps {
  editor: LexicalEditor
  fontWeight: string
  fontFamily?: string
}

export function ToolbarFontWeightSelect({
  editor,
  fontWeight,
  fontFamily = 'Inter, sans-serif',
}: ToolbarFontWeightSelectProps) {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const allowedFontWeights = useMemo(
    () =>
      Object.values(fontsConfig).find((font) => font.fontFamily === fontFamily)
        ?.config?.allowedFontWeights || [],
    [fontFamily],
  )

  const handleFontWeightChange = (newValue: string) => {
    editor.update(() => {
      const selection = $getSelection()
      if ($INTERNAL_isPointSelection(selection)) {
        $patchStyleText(selection, {
          'font-weight': newValue,
        })
      }
    })
    setOpen(false)
  }

  const selectedFontWeight = FONT_WEIGHT_OPTIONS.find(
    (option) => option.value === fontWeight,
  )

  return (
    <div className="wysiwyg-toolbar-toggle-group" ref={ref}>
      <SelectProvider
        open={open}
        setOpen={setOpen}
        value={fontWeight || ''}
        setValue={handleFontWeightChange}
      >
        <Select className="wysiwyg-toolbar-toggle-item wysiwyg-toolbar-toggle-item--font-weight">
          <span className="wysiwyg-select__selected-font">
            {selectedFontWeight?.label || 'Regular'}
          </span>
          <Icon
            provider="phosphor"
            icon="CaretDown"
            weight="bold"
            size={8}
            className="tw-shrink-0"
          />
        </Select>
        <SelectPopover
          sameWidth
          className="wysiwyg-select-content wysiwyg-select-content--font-weight"
          onBlurCapture={(event) => {
            // prevent the blur event from propagating window and messing up with useWindowFocus hook
            event.preventDefault()
            event.stopPropagation()
            event.nativeEvent.preventDefault()
            event.nativeEvent.stopPropagation()
          }}
          onWheelCapture={(e) => {
            if (Math.abs(e.deltaY) > Math.abs(e.deltaX) * 1.5) {
              e.stopPropagation()
            }
          }}
        >
          {open && (
            <div className="wysiwyg-select__list">
              {FONT_WEIGHT_OPTIONS.map((option) => {
                const isDisabled = !allowedFontWeights.includes(
                  Number(option.value) as FontWeightType,
                )
                return (
                  <div
                    key={option.value}
                    role="option"
                    data-selected={option.value === fontWeight}
                    className={`wysiwyg-select__option ${
                      isDisabled ? 'wysiwyg-select__option--disabled' : ''
                    }`}
                    onClick={() =>
                      !isDisabled && handleFontWeightChange(option.value)
                    }
                  >
                    <div className="wysiwyg-select__option-text">
                      {option.label}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </SelectPopover>
      </SelectProvider>
    </div>
  )
}
