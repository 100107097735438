import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'
import { ContentBlock } from '@sceneio/graphql-queries/dist/generated/graphqlTypes'
import flatten from 'flat'
import { memoize } from 'proxy-memoize'

export const selectContentStatus = (state: RootState) => {
  return state.content.status
}

export const selectContentStatusError = (state: RootState) => {
  return state.content.error
}

export const selectContentPublishState = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.publishState
}

export const selectContentMeta = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.meta
}

export const selectContent = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return []
  }

  return state.content.entity
}

export const selectContentBlocks = createSelector(
  [
    (state: RootState) => state.content.status,
    (state: RootState) => state.content,
  ],
  (contentStatus, content) => {
    if (contentStatus !== 'succeeded') {
      return []
    }

    return content.entity?.contentBlocks || []
  },
)

// merged content blocks with reusable content blocks drafts
export const selectMergedContentBlocksWithReusableContentBlocksDrafts =
  createSelector(
    [
      (state: RootState) => state.content.status,
      (state: RootState) => state.content.entity?.contentBlocks,
    ],
    (contentStatus, contentBlocks) => {
      if (contentStatus !== 'succeeded') {
        return []
      }
      if (!contentBlocks) {
        return []
      }

      return contentBlocks.map(
        ({ reusableContentBlockDraft, ...contentBlock }) =>
          reusableContentBlockDraft || contentBlock,
      )
    },
  )

export const selectRenderableContentBlocks = memoize((state: RootState) => {
  return (
    (state.content.entity?.contentBlocks?.filter(({ isRenderable }) => {
      return isRenderable
    }) as ContentBlock[]) || []
  )
})

export const selectRenderableContentBlocksLength = (state: RootState) => {
  return state.content.entity?.contentBlocks?.filter(({ isRenderable }) => {
    return isRenderable
  }).length || 0
}

export const selectNonRenderableContentBlocks = memoize((state: RootState) => {
  return (
    (state.content.entity?.contentBlocks?.filter(({ isRenderable }) => {
      return !isRenderable
    }) as ContentBlock[]) || []
  )
})

export const selectContentId = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.id
}


export const selectHashContentBlocksConfigs = memoize((state: RootState) => {
  return state.content.entity?.hashContentBlocksConfigs || {}
})

export const selectPartialContent = createSelector(
  [
    (state: RootState) => state.content.status,
    (state: RootState) => state.content.entity,
  ],
  (contentStatus, entity) => {
    if (contentStatus !== 'succeeded') {
      return null
    }
    if (!entity) {
      return null
    }
    const { id, data, references } = entity

    return { id, data, references }
  },
)

export const selectPageMetaTitle = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return ''
  }

  return state.content.entity.meta.page.title
}

export const selectLayoutType = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.data.type
}

export const selectContentPreferences = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return {}
  }

  return state.content.entity?.preferences || {}
}

export const selectContentPreferencesAiPrompt = (state: RootState) => {
  return state.content.entity?.preferences?.AIPrompt || ''
}

export const selectBlockIds = memoize(
  (state: RootState) =>
    state.content.entity?.contentBlocks.map((block) => block.id) as string[],
)

export const selectRenderableContentBlockIds = memoize((state: RootState) => {
  return state.content.entity?.contentBlocks.reduce<string[]>(
    (acc, { id, isRenderable }) => {
      if (isRenderable) {
        acc.push(id)
      }
      return acc
    },
    [],
  ) as string[]
})

export const selectRawBlockById = createSelector(
  [selectContentBlocks, (_, blockId) => blockId],
  (contentBlocks, blockId) => {
    const contentBlock = contentBlocks.find((block) => block.id === blockId)

    return contentBlock
  },
)

export const selectRawBlockByCid = createSelector(
  [selectContentBlocks, (_, blockCid) => blockCid],
  (contentBlocks, blockCid) => {
    const contentBlock = contentBlocks.find((block) => block.cid === blockCid)

    return contentBlock
  },
)

export const selectContentPaths = createSelector(
  [
    (state: RootState) => state.content.status,
    (state: RootState) => state.content?.entity?.paths,
  ],
  (contentStatus, contentPaths) => {
    if (contentStatus !== 'succeeded') {
      return []
    }

    return contentPaths || []
  },
)
export const selectContentParentId = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.parentId
}

export const selectBlocksAnchorIds = memoize((state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return []
  }

  const flattenContentBlocks = flatten(state.content.entity.contentBlocks, {
    safe: false,
  }) as Record<string, any>

  const blocksAnchorIds = Object.keys(flattenContentBlocks).reduce<string[]>(
    (blocksAnchorIds, path) => {
      if (path.endsWith('anchorId') && flattenContentBlocks[path]) {
        blocksAnchorIds.push(flattenContentBlocks[path])
      }
      return blocksAnchorIds
    },
    [],
  )

  return blocksAnchorIds
})

export const selectContentHasUpdates = createSelector(
  [
    (state: RootState) =>
      state.content.entity?.contentBlocks.some(({ updatedAt }) => updatedAt),
    (state: RootState) => Boolean(state.content.entity?.updatedAt),
    (state: RootState) => Boolean(state.content.entity?.paths[0]?.updatedAt),
  ],
  (isContentBlocksUpdated, isContentUpdated, isContentPathUpdated) => {
    return isContentUpdated || isContentPathUpdated || isContentBlocksUpdated
  },
)

export const selectContentRestoreInfo = (state: RootState) => {
  return state.content.entity?.restoreInfo
}
