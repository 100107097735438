import { InferType, array, bool, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { gridComponentSchema } from '../../components/gridComponentSchema'
import { imageComponentSchema } from '../../components/imageComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'

export const testimonialVariant1CardSchema = containerComponentSchema
  .shape({
    id: idSchema.required(),
    components: object({
      logo: imageComponentSchema,
      quote: textComponentSchema,
      authorContainer: containerComponentSchema.shape({
        components: object({
          avatar: imageComponentSchema,
          name: textComponentSchema,
          company: textComponentSchema,
        }).noUnknown(),
      }),
    }).noUnknown(),
  })
  .noUnknown()

export const testimonialVariant1BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema,
    heading: textComponentSchema,
    text: textComponentSchema,
    testimonials: gridComponentSchema
      .shape({
        items: array().of(testimonialVariant1CardSchema),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type TestimonialVariant1Block = InferType<
  typeof testimonialVariant1BlockSchema
>

export type TestimonialVariant1Card = InferType<
  typeof testimonialVariant1CardSchema
>
