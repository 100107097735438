import {
  $getSelection,
  COMMAND_PRIORITY_CRITICAL,
  EditorState,
  PASTE_COMMAND,
} from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'
import { $insertDataTransferForRichText } from '@lexical/clipboard'

export function OnChangePlugin({
  onChange,
}: {
  onChange: (editorState: EditorState) => void
}) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      (ev: ClipboardEvent) => {
        const selection = $getSelection()
        if (ev && ev.clipboardData && selection) {
          const htmlText = ev.clipboardData.getData('text/html') || ''
          const plainText = ev.clipboardData?.getData('text/plain') || ''

          const isInternalSceneContentPaste = htmlText.match(
            /<div data-sceneio[^>]*>(.*)<\/div>/,
          )?.[1]

          let clipboardData = ev.clipboardData

          if (isInternalSceneContentPaste) {
            clipboardData = new DataTransfer()
            clipboardData?.setData('text/plain', plainText)
          }

          $insertDataTransferForRichText(clipboardData, selection, editor)
          ev.preventDefault() // Unsure if necessary
        }

        return true
      },
      COMMAND_PRIORITY_CRITICAL, // Not necessary. Ensures it is run before the internal handler.
    )
  }, [editor])

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState, dirtyLeaves }) => {
      // Prevent all updates not initiated by the editor itself
      if (dirtyLeaves.size === 0) {
        return
      }

      onChange(editorState)
    })
  }, [editor, onChange])
  return null
}
