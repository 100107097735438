import * as Sentry from '@sentry/react'
import { CapturedNetworkRequest, posthog, PostHogConfig } from 'posthog-js'
import { NotificationContainer } from '@sceneio/cms-notifications'
import { Provider } from '@sceneio/content-store/lib/redux'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
// import { HashRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { StrictMode } from 'react'
import App from './App'
import { ErrorBoundary } from './ErrorBoundary'
import { persistor, store } from './store/storeInstance'
import { PostHogProvider } from 'posthog-js/react'
import { version } from '../package.json'
import { captureConsoleIntegration } from '@sentry/react'
import { IntercomProvider } from 'react-use-intercom'

const container = document.getElementById('root')
const root = createRoot(container!)

const posthogOptions: Partial<PostHogConfig> = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  enable_recording_console_log: true,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true, // Highly recommended as a minimum!!
    },
    maskCapturedNetworkRequestFn: (request: CapturedNetworkRequest) => {
      // ignore KeepAliveMutation
      if (Boolean(request?.requestBody?.match(/KeepAliveMutation/))) {
        return null
      }

      // redacted token
      request.responseBody = request.responseBody?.replace(
        /("token"\s*:\s*{\s*"value"\s*:\s*")[^"]+(")/g,
        '$1redacted-token$2',
      )
      return request
    },
  },
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: version,
  normalizeDepth: 10,
  normalizeMaxBreadth: 100000,
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration(),
    captureConsoleIntegration({ levels: ['error'] }),
    posthog.sentryIntegration({
      organization: import.meta.env.VITE_SENTRY_ORG,
      projectId: import.meta.env.VITE_SENTRY_PROJECT,
      severityAllowList: ['error', 'info'], // optional: here is set to handle captureMessage (info) and captureException (error)
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/.+\.scene\.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const AppWrapper = () => {
  const isDev = import.meta.env.DEV

  const content = (
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_TOKEN}
      options={posthogOptions}
    >
      <IntercomProvider
        appId="mn5rt94e"
        onUnreadCountChange={(count) => {
          const intercomMsgBadge = document.querySelector(
            '#intercom-message-badge',
          ) as HTMLDivElement
          if (intercomMsgBadge) {
            intercomMsgBadge.style.display = count > 0 ? 'flex' : 'none'
          }
        }}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <App />
            </Router>
            <NotificationContainer options={{ hideProgressBar: true }} />
          </PersistGate>
        </Provider>
      </IntercomProvider>
    </PostHogProvider>
  )

  return isDev ? <StrictMode>{content}</StrictMode> : content
}

root.render(
  <ErrorBoundary>
    <AppWrapper />
  </ErrorBoundary>,
)
