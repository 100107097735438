import * as Popover from '@radix-ui/react-popover'
import { Icon } from '@sceneio/ui-icons'

import { SnippetTypeToIcon } from '../SnippetsDropdown/components/SnippetTypeToIcon'
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { SnippetValueMap } from '../SnippetsDropdown/components/SnippetValueMap'
import { useOnClickOutside } from '@sceneio/hooks/lib/useOnClickOutside'
import { useFormContext, SNIPPET_TYPE } from '../../form/context/FormContext'
import type { JSX } from 'react'

export const SnippetChangeDropdown = ({
  snippetsType,
  children,
  popoverClassname,
  snippetId,
  onAssignSnippet,
  editSnippetMode,
  onOpenChange,
}: {
  showSaveButton?: boolean
  children?: JSX.Element
  popoverClassname?: string
  snippetsType: SNIPPET_TYPE
  snippetId?: string
  onOpenChange?: (open: boolean) => void
  editSnippetMode?: boolean
  onAssignSnippet?: (
    id: string,
    { shouldReplace }?: { shouldReplace?: boolean },
  ) => void
}) => {
  const [open, setOpen] = useState(false)
  const { snippets = [], onDeleteSnippet } = useFormContext({ snippetsType })

  const containerRef = useRef<HTMLDivElement>(null)

  const handleOnChange = (id: string) => {
    setOpen(false)
    if (snippetId === id) return

    if (onAssignSnippet) {
      onAssignSnippet(id, { shouldReplace: true })
    }
  }

  useOnClickOutside(
    () => {
      if (open) {
        setOpen(false)
      }
    },
    {
      refs: [containerRef],
      ignoreClass: ['tw-dropdown-content'],
    },
  )

  useEffect(() => {
    if (onOpenChange) {
      onOpenChange(open)
    }
  }, [open])

  return (
    <div className="tw-flex" ref={containerRef}>
      <Popover.Root open={open}>
        <Popover.Trigger asChild>
          <button
            type="button"
            className="tw-trigger-btn tw-w-full tw-h-full"
            onClick={(e) => {
              if (e.metaKey || e.shiftKey) {
                return
              }
              e.stopPropagation()
              if (!editSnippetMode) {
                setOpen(!open)
              }
            }}
          >
            {children}
          </button>
        </Popover.Trigger>
        <Popover.Content
          className={clsx(
            'tw-dropdown-content tw-rounded tw-min-w-[290px] tw-max-w-[400px] tw-bg-white tw-border tw-border-border-color tw-text-xs tw-flex tw-flex-col tw-z-10',
            popoverClassname,
          )}
          sideOffset={5}
          align="end"
        >
          <div className="tw-py-2 tw-max-h-[300px] tw-overflow-y-auto">
            <ul className="tw-pl-0 tw-mb-0">
              {snippets.map(({ id, data = {}, name }, idx) => (
                <li
                  key={idx}
                  className="tw-flex tw-justify-between tw-gap-3 tw-p-3 tw-cursor-default tw-relative hover:tw-bg-form-field-bg tw-group/snippet"
                  onClick={() => handleOnChange(id)}
                >
                  <div className="tw-flex tw-items-center tw-gap-1 tw-flex-1 tw-min-w-px tw-max-w-[40%]">
                    <SnippetTypeToIcon
                      snippetType={snippetsType}
                      value={data.value}
                    />
                    <span className="tw-font-semibold tw-truncate">{name}</span>
                  </div>
                  <div className="tw-flex tw-items-center tw-truncate">
                    <SnippetValueMap
                      snippetType={snippetsType}
                      value={data.value}
                    />
                    <button
                      type="button"
                      className={clsx(
                        'tw-text-danger tw-px-1 tw-ml-1 tw-rounded-sm focus-visible:tw-outline-none hover:tw-bg-gray-4 tw-invisible group-hover/snippet:tw-visible',
                        { 'tw-invisible': snippetId !== id },
                      )}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (onDeleteSnippet) {
                          onDeleteSnippet(id)
                        }
                      }}
                    >
                      <Icon provider="phosphor" icon="Trash" size={15} />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}
