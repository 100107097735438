import React from 'react'
import clsx from 'clsx'
import { Container } from '../../../../components/Container/Container'
import Image from '../../../../components/Image/Image'
import Text from '../../../../components/Text/Text'
import resolveComponentId from '../../../../tools/helpers/resolveComponentId'
import type { TestimonialVariant1Card } from '@sceneio/schemas/lib/types'

export function TestimonialCard({
  config,
  blockId,
  editor,
  isAnimated = true,
}: {
  config: TestimonialVariant1Card
  blockId?: string
  editor: any
  isAnimated?: boolean
}) {
  const {
    components: { logo, quote, authorContainer },
    ...restConfig
  } = config || {}

  return (
    <Container
      {...restConfig}
      className={clsx('sc-testimonial-card')}
      name="group"
      editor={editor}
      flow={{ data: { '--flow-default': 'column' } }}
    >
      <Image
        {...logo}
        editor={{
          ...editor,
          id: `${editor?.id}.logo`,
        }}
        isAnimated={isAnimated}
      />
      <Text
        {...quote}
        editor={{
          ...editor,
          id: `${editor?.id}.quote`,
        }}
        cid={resolveComponentId(`${blockId}`, 'quote')}
        isAnimated={isAnimated}
      />
      <Container
        {...authorContainer}
        name="component"
        editor={{
          ...editor,
          id: `${editor?.id}.authorContainer`,
        }}
        className="sc-testimonial-card__author-container"
      >
        <Image
          {...authorContainer.components.avatar}
          transformationOptions={{ cover: '1:1' }}
          editor={{
            ...editor,
            id: `${editor?.id}.authorContainer.avatar`,
          }}
          isAnimated={isAnimated}
        />
        <div className="sc-testimonial-card__name-container">
          <Text
            {...authorContainer.components.name}
            editor={{
              ...editor,
              id: `${editor?.id}.authorContainer.name`,
            }}
            cid={resolveComponentId(`${blockId}`, 'name')}
            isAnimated={isAnimated}
          />
          <Text
            {...authorContainer.components.company}
            editor={{
              ...editor,
              id: `${editor?.id}.authorContainer.company`,
            }}
            cid={resolveComponentId(`${blockId}`, 'company')}
            isAnimated={isAnimated}
          />
        </div>
      </Container>
    </Container>
  )
}
