type ChildType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'paragraph'
  | 'array-item'

export interface ILexical {
  root: {
    children: {
      children: {
        detail: number
        format: number
        mode: string
        style: string
        text: string
        type: string
        version: number
      }[]
      direction: string | null
      format: string
      indent: number
      type: string
      version: number
      tag?: string
    }[]
    direction: string | null
    format: string
    indent: number
    type: string
    version: number
  }
}

export function textToLexical(
  text: string,
  childType: ChildType = 'paragraph',
): string {
  const headingArr = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
  const isHeading = headingArr.includes(childType)
  const type = isHeading ? 'heading' : childType

  return JSON.stringify({
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: 'normal',
              style: '',
              text: text,
              type: 'text',
              version: 1,
            },
          ],
          direction: null,
          format: '',
          indent: 0,
          type: type,
          version: 1,
          ...(isHeading ? { tag: childType } : {}),
        },
      ],
      direction: null,
      format: '',
      indent: 0,
      type: 'root',
      version: 1,
    },
  } as ILexical)
}
