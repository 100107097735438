import { BreakpointType } from '../../atoms/BreakpointDropdown/BreakpointDropdown'
import { useFormContext } from '../../form/context/FormContext'
import { ScrollToViewHelper } from '../../helpers/ScrollToViewHelper'
import { DefaultGenericSettings } from './DefaultGenericSettings'
import { ResponsiveGenericSettings } from './ResponsiveGenericSettings'
import type { JSX } from 'react'

export type GenericSettingsPropType = {
  className?: string
  title?: string
  formFieldRenderer: (arg0: { breakpoint: BreakpointType | 'default' }) => {
    fieldNames: string[]
    children: JSX.Element
  } | null
  additionalFormFieldRenderer?: (arg0: {
    breakpoint: BreakpointType | 'default'
    values: Record<string, any>
  }) => {
    fieldNames: string[]
    children: JSX.Element
    placement?: 'top' | 'bottom'
  } | null
}

export const GenericSettings = ({
  className,
  title = 'Layout',
  formFieldRenderer = () => null,
  additionalFormFieldRenderer = () => null,
}: GenericSettingsPropType) => {
  const { forceBreakpoint } = useFormContext()

  if (forceBreakpoint) {
    return (
      <ResponsiveGenericSettings
        className={className}
        title={title}
        formFieldRenderer={formFieldRenderer}
        additionalFormFieldRenderer={additionalFormFieldRenderer}
      />
    )
  }

  return (
    <>
      <ScrollToViewHelper type="generic" />
      <DefaultGenericSettings
        className={className}
        title={title}
        formFieldRenderer={formFieldRenderer}
        additionalFormFieldRenderer={additionalFormFieldRenderer}
      />
    </>
  )
}
