import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { ContentSliceType } from '../content/contentSliceTypes'

export const selectMode = (state: RootState) => {
  return state.editor.mode
}

export const selectEditor = (state: RootState) => {
  return state.editor.editor
}

export const selectReusableContentBlocks = (state: RootState) => {
  return state.editor.reusableContentBlocks
}

export const selectReusableContentBlockById = createSelector(
  [selectReusableContentBlocks, (_, blockId: string) => blockId],
  (reusableContentBlocks, blockId) => {
    return reusableContentBlocks.find(
      (reusableContentBlock) => reusableContentBlock.id === blockId,
    )
  },
)

export const selectZoom = (state: RootState) => {
  return state.editor.zoom
}

export const selectCollaborators = (state: RootState) => {
  return state.editor.collaborators
}

export const selectTriggerVersionHistoryRefetch = (state: RootState) => {
  return state.editor.triggerVersionHistoryRefetch
}

export const selectShowComments = (state: RootState) => {
  return state.editor.showComments
}

export const selectShowResponsiveViews = (state: RootState) => {
  return state.editor.showResponsiveViews
}

export const selectIsBriefGeneratingByAI = (state: RootState) => {
  return state.editor.isBriefGeneratingByAi
}

export const selectShowSitemap = (state: RootState) => {
  return state.editor.showSitemap
}

export const selectBlockPicker = (state: RootState) => {
  return state.editor.blockPicker
}
export const selectBlockPickerShow = (state: RootState) => {
  return state.editor.blockPicker.show
}

export const selectShowContainerGuides = (state: RootState) => {
  return state.editor.showContainerGuides
}

export const selectDesignerModal = (state: RootState) => {
  return state.editor.designerModal
}
export const selectPageSettingsForm = (state: RootState) => {
  return state.editor.pageSettingsForm
}
export const selectSiteSettingsForm = (state: RootState) => {
  return state.editor.siteSettingsForm
}
export const selectShouldZoomToFit = (state: RootState) => {
  return state.editor.shouldZoomToFit
}

export const selectShowLeftSidebar = (state: RootState) => {
  return state.editor.showLeftSidebar
}

export const selectShowRightSidebar = (state: RootState) => {
  return (
    state.editor.showRightSidebar &&
    (state.content as ContentSliceType).status === 'succeeded'
  )
}

export const selectShowKeySheet = (state: RootState) => {
  return state.editor.showKeySheet
}

export const selectShowInviteUsersDropdown = (state: RootState) => {
  return state.editor.showInviteUsersDropdown
}

export const selectSelectedEntity = (state: RootState) => {
  return state.editor.selectedEntity
}

export const selectPersistentComponentSelection = (state: RootState) => {
  return state.editor.persistentComponentSelection
}

export const selectSelectedEntityBlockId = (state: RootState) => {
  return state.editor.selectedEntity.selectedBlockId
}

export const selectSelectedEntityComponentId = (state: RootState) => {
  return state.editor.selectedEntity.selectedComponentId
}

export const selectSelectedFrame = (state: RootState) => {
  return state.editor.selectedEntity.selectedFrame
}

export const selectContent = (state: RootState) => {
  return state.editor.content
}

export const selectIntegrations = (state: RootState) => {
  return state.editor.integrations
}

export const selectIsLayoutSelected = (state: RootState) => {
  return state.editor.selectedEntity.layout
}

export const selectCanvasState = (state: RootState) => {
  return state.editor.canvasState
}

export const selectDndDraggableData = (state: RootState) => {
  return state.editor.dndDraggableData
}

export const selectDynamization = (state: RootState) => {
  return state.editor.dynamization
}

export const selectIsUndoEnabled = (state: RootState) =>
  state.editor.undoEnabled
export const selectIsRedoEnabled = (state: RootState) =>
  state.editor.redoEnabled

export const selectIsDynamizationFinished = createSelector(
  [selectDynamization],
  (dynamization) => {
    return (
      Object.keys(dynamization).length > 0 &&
      Object.keys(dynamization).every((value) => dynamization[value])
    )
  },
)

export const selectDynamizationByBlockId = createSelector(
  [selectDynamization, (state, blockId: string) => ({ state, blockId })],
  (dynamization, { blockId }) => {
    return dynamization[blockId]
  },
)

export const selectShowPageHistory = (state: RootState) => {
  return state.editor.showPageHistory
}

export const selectWorkspaceId = (state: RootState) => {
  return state.editor.workspaceId
}

export const selectMyWorkspaceId = (state: RootState) => {
  return state.editor.myWorkspaceId
}

export const selectIsMyWorkspace = (state: RootState) => {
  if (!state.editor.myWorkspaceId || !state.editor.workspaceId) {
    return false
  }

  return state.editor.myWorkspaceId === state.editor.workspaceId
}

export const selectTemplatePicker = (state: RootState) => {
  return state.editor.templatePicker
}
