import React from 'react'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import clsx from 'clsx'
import type { IconComponent as IconComponentType } from '@sceneio/schemas/lib/types'
import { WebIcon } from '../WebIcon/WebIcon'
import { resolveBreakpointClasses } from '../../helpers/resolveBreakpointClasses'
import { resolveDefaultClasses } from '../../helpers/resolveDefaultClasses'
import { BreakpointEnum } from '../../helpers/breakpoints'
import { preprocessIconConfig } from './preprocessIconConfig'

function IconComponent({
  responsiveConfig,
  mode = 'leading',
  weight,
  width,
  gap,
  color,
  className,
  style,
}: IconComponentType) {
  const config = preprocessIconConfig({ responsiveConfig })

  return (
    <>
      {Object.entries(config).map(([bp, states]) =>
        Object.entries(states).map(([state, value]) => {
          return (
            <WebIcon
              key={`${bp}-${state}`}
              icon={value.name}
              provider={value.provider}
              weight={weight}
              className={clsx(
                'sc-icon-component',
                {
                  'sc-icon-component--leading': mode === 'leading',
                  'sc-icon-component--trailing': mode === 'trailing',
                  [`sc-icon-component--${bp}-${state}`]: bp && state,
                  [resolveBreakpointClasses({
                    breakpoint: bp as BreakpointEnum,
                    elementClassName: 'sc-icon-component',
                  })]: bp !== 'default',
                  [resolveDefaultClasses({
                    elementClassName: 'sc-icon-component',
                    breakpointsArr: Object.keys(config) as BreakpointEnum[],
                  })]: bp === 'default',
                },
                className,
              )}
              style={{
                ...style,
                ...getRootLevelCssVariables({
                  cssVariables: {
                    width,
                    gap,
                    color,
                  },
                  prefix: 'icon',
                }),
              }}
            />
          )
        }),
      )}
    </>
  )
}
export const plugins = {
  js: [],
  css: [],
}

export default IconComponent
