import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { asyncThunkFetcher } from '../../helpers/asyncThunkFetcher'
import { createGraphqlAsyncThunkByDocument } from '../../helpers/createGraphqlAsyncThunk'
import type { AppDispatch, RootState } from './../../store'
import { AssetsSliceType, FontStyleType } from './assetsSliceTypes'
import { convertFontStyleAssetsToFontConfig } from '@sceneio/font-tools/lib/convertFontStyleAssetsToFontConfig'
import { getUsedFonts } from '@sceneio/ui-core/src/fonts/helpers/getUsedFonts'

export * from './assetsSliceSelectors'

// ---------------
// Initial State
// ---------------
export const initialState: AssetsSliceType = {
  fontStyles: {
    entities: [],
    status: 'idle',
    error: null,
  },
  usedFonts: [],
} as AssetsSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Thunks
// ---------------

export const fetchFonts = createGraphqlAsyncThunkByDocument<
  'FontStylesQueryDocument',
  'fontStyles'
>()('fontStyles/fetchData', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'FontStylesQueryDocument',
    selector: 'fontStyles',
    variables: queryVariables,
    thunkAPI,
  })
})

export const createFontStyle = createGraphqlAsyncThunkByDocument<
  'FontStyleCreateMutationDocument',
  'createFontStyle'
>()('fontStyle/create', async ({ queryVariables, thunkOptions }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'FontStyleCreateMutationDocument',
    selector: 'createFontStyle',
    variables: queryVariables,
    thunkAPI,
    rejectQueries: ['FONTSTYLES'],
  }).then((res) => {
    if (thunkOptions?.onSuccess) {
      thunkOptions.onSuccess(res)
    }

    return res
  })
})

export const deleteFontStyle = createGraphqlAsyncThunkByDocument<
  'FontStyleDeleteMutationDocument',
  'deleteFontStyle'
>()('font/delete', async ({ queryVariables, thunkOptions }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'FontStyleDeleteMutationDocument',
    selector: 'deleteFontStyle',
    variables: queryVariables,
    thunkAPI,
    rejectQueries: ['FONTSTYLES'],
  }).then((res) => {
    if (thunkOptions?.onSuccess) {
      thunkOptions.onSuccess()
    }

    return res
  })
})

export const updateFontStyle = createGraphqlAsyncThunkByDocument<
  'FontStyleUpdateMutationDocument',
  'updateFontStyle'
>()('font/update', async ({ queryVariables, thunkOptions }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'FontStyleUpdateMutationDocument',
    selector: 'updateFontStyle',
    variables: queryVariables,
    thunkAPI,
    rejectQueries: ['FONTSTYLES'],
  }).then((res) => {
    if (thunkOptions?.onSuccess) {
      thunkOptions.onSuccess()
    }

    return res
  })
})

export const loadUsedFontsFromStore = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()

    const contentBlocks = state.content.entity?.contentBlocks || []
    const contentBlocksData = contentBlocks.map(({ config }) => config)
    const rbDrafts = contentBlocks.map(
      ({ reusableContentBlockDraft }) =>
        reusableContentBlockDraft?.config || {},
    )

    const snippets = state.snippets.entities

    const customFontStyles = state.assets.fontStyles.entities
    const normalizedCustomFontStyles =
      convertFontStyleAssetsToFontConfig(customFontStyles)

    const usedFonts = getUsedFonts(
      { contentBlocksData: [...contentBlocksData, ...rbDrafts], snippets },
      normalizedCustomFontStyles,
    )

    dispatch(addUsedFonts(usedFonts))
  }
}

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    resetsAssetsState: () => {
      return initialState
    },
    wsCreateFont: (state, action: PayloadAction<FontStyleType>) => {
      const actionPayload = action.payload
      state.fontStyles.entities = [...state.fontStyles.entities, actionPayload]
      const normalizedFont = convertFontStyleAssetsToFontConfig([actionPayload])
      if (normalizedFont && Object.keys(normalizedFont).length > 0) {
        state.usedFonts.push(Object.values(normalizedFont)[0])
      }
    },
    wsUpdateFont: (state, action: PayloadAction<FontStyleType>) => {
      const actionPayload = action.payload

      const fontStyleIndex = state.fontStyles.entities.findIndex(
        (fontStyle) => fontStyle.id === actionPayload.id,
      )

      if (fontStyleIndex > -1) {
        state.fontStyles.entities[fontStyleIndex] = actionPayload
      }
    },
    wsDeleteFont: (state, action: PayloadAction<FontStyleType>) => {
      const { id } = action.payload

      state.fontStyles.entities = state.fontStyles.entities.filter(
        (fontStyle) => fontStyle.id !== id,
      )
    },
    addUsedFonts: (
      state,
      action: PayloadAction<AssetsSliceType['usedFonts']>,
    ) => {
      if (state.usedFonts.length < 1) {
        state.usedFonts = action.payload
      } else {
        // Filter out fonts that are already in the store
        const newFonts = action.payload.filter(
          (font) =>
            !state.usedFonts.some((f) => f.fontFamily === font.fontFamily),
        )

        state.usedFonts = [...state.usedFonts, ...newFonts]
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase('USER:LOGOUT', () => {
        return initialState
      })
      .addCase(fetchFonts.pending, (state, action) => {
        state.fontStyles.status = 'loading'
      })
      .addCase(fetchFonts.fulfilled, (state, action) => {
        state.fontStyles.status = 'succeeded'
        const payload = action.payload as FontStyleType[]
        state.fontStyles.entities = payload || []
      })
      .addCase(fetchFonts.rejected, (state, action) => {
        state.fontStyles.status = 'failed'
        state.fontStyles.error = action.error.code || null
      })
      .addCase(createFontStyle.fulfilled, (state, action) => {
        if (state.fontStyles.status === 'succeeded') {
          const fontStyle = action.payload as FontStyleType
          const normalizedFont = convertFontStyleAssetsToFontConfig([fontStyle])

          state.fontStyles.entities = [...state.fontStyles.entities, fontStyle]
          if (normalizedFont && Object.keys(normalizedFont).length > 0) {
            state.usedFonts.push(Object.values(normalizedFont)[0])
          }
        }
      })
      .addCase(updateFontStyle.fulfilled, (state, action) => {
        const { id: fontStyleId } = action.meta.arg.queryVariables

        const fontStyleIndex = state.fontStyles.entities.findIndex(
          (fontStyle) => fontStyle.id === fontStyleId,
        )

        if (fontStyleIndex > -1) {
          state.fontStyles.entities[fontStyleIndex] =
            action.payload as FontStyleType
        }
      })
      .addCase(deleteFontStyle.pending, (state, action) => {
        const { id } = action.meta.arg.queryVariables

        state.fontStyles.entities = state.fontStyles.entities.filter(
          (fontStyle) => fontStyle.id !== id,
        )
      })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAssetsDispatch: () => AppDispatch = useDispatch
export const useAssetsSelector: TypedUseSelectorHook<RootState> = useSelector

// Action creators are generated for each case reducer function
export const {
  resetsAssetsState,
  wsCreateFont,
  wsUpdateFont,
  wsDeleteFont,
  addUsedFonts,
} = assetsSlice.actions

export default assetsSlice.reducer
